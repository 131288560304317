<template>
    <image-slider></image-slider>
</template>

<script>
    import {mapActions} from 'vuex'
    import {mapGetters} from 'vuex'
    import LoadingPanel from "./components/loading-panel.vue";
    import ImageSlider from "../venveo-modal/components/image-slider.vue";

    export default {
        components: {
            ImageSlider,
        },
        name: "app",
        created() {
            // this.$root.$on('open-modal', this.openModal)
        },
        beforeDestroy() {
            // this.$root.$off('open-modal', this.openModal)
        },
        computed: {
            ...mapGetters(['isLoading','modalVisible']),
            displayStyle() {
                return 'display:' + (this.modalVisible ? 'block':'none')
            }
        },
        methods: {
            ...mapActions(['openModal','hideModal'])
        }
    }
</script>

<style scoped>

</style>