/**
 * Controller Module
 */

const _this =  {
    elem: null,
    prevIndex: null,
    dropjs: document.querySelectorAll('[data-dropjs]'),
    dropdownInput: null,
    dropdownSel: null,
    dropdownLbl: null,
    dropdownUL: null,
    dropdownLI: null,
    activeLI: null,
    dropdownSearch: null,
    dropdownLIAttrbs: null,
    setOptionsTemp: [],
    setOptionsTextTemp: [],
    optionsDef: [],
    optionsStatus: false
}

function dropjs() {
    _this.dropjs.forEach(function(elem, index) {
        elem.addEventListener('click', function() {
            // store clicked dropdown to `_this.elem`
            _this.elem = elem
            // if not the repeated dropdown is being clicked
            if( index !== prevIndex() && prevIndex() !== null ) {
                // set previous dropdown to it's original dropdown options
                renderOptionsDef()
                setSelectedOption()
                // set to FALSE to update to vars for the newly clicked dropdown
                _this.optionsStatus = false
                // clear previous dropdown search input
                _this.dropdownSearch.value = ''
            }
            // pass in index value
            _this.prevIndex = index
            updateElements()
            generateOptions()
            dropdownToggle()

            // Add new event for the dropdown input search
            _this.dropdownSearch.addEventListener('input', filterOption)
            // Add new event for the dropdown `UP`, `DOWN`, `RETURN` keys
            document.addEventListener('keydown', optionHighlightKey)
        })
    })
}

function prevIndex() {
    return _this.prevIndex
}

// Set new values for the clicked dropdown
function updateElements() {
    _this.dropdownUL = _this.elem.querySelector('[data-dropjs-list]')
    _this.dropdownLI = _this.dropdownUL.querySelectorAll('[data-dropjs-item]')
    _this.activeLI = _this.elem.querySelector('.is-selected') || _this.elem.querySelector('[data-dropjs-list] li')
    _this.dropdownSearch = _this.elem.querySelector('[data-dropjs-search]')
    _this.dropdownLbl = _this.elem.querySelector('[data-dropjs-label]')
    _this.dropdownSel = _this.elem.querySelector('[data-dropjs-select]')
    optionEvent()
}

// Set current option based on current select option value
function setSelectedOption(){
    let currentValSel = _this.dropdownSel.options[0].innerText

    clearSelectedOption()
    _this.dropdownUL.querySelectorAll('li').forEach(function(item){
        if( item.innerText ===  currentValSel) {
            item.classList.add('is-selected')
        }
    })
}

// Option event for dropdown option click
function optionEvent() {
    _this.dropdownLI.forEach(function(item) {
        item.addEventListener('click', function(event){
            updateDropdownVal(event.target)
            clearSelectedOption()
            event.target.classList.add('is-selected')
        })
    })
}

function clearSelectedOption() {
    _this.dropdownUL.querySelectorAll('li').forEach(function(item) {
        item.classList.remove('is-selected')
    })
}

function updateDropdownVal(selectedItem) {
    if( selectedItem && !selectedItem.classList.contains('no-results') ) {
        _this.activeLI = selectedItem
    }
    if( !_this.activeLI.classList.contains('no-results') ) {
        const selVal = _this.activeLI.innerText
        const selDataVal = _this.activeLI.getAttribute('data-value')
        // For Label
        _this.dropdownLbl.innerText = selVal
        // For Select
        // set option value
        _this.dropdownSel.options[0].setAttribute('value', selDataVal)
        // set option text
        _this.dropdownSel.options[0].innerText = selVal
        // Set option selected
        _this.dropdownSel.options[0].setAttribute('selected', '')
    }
}

// Dropdown toggle function
function dropdownToggle() {
    if (_this.elem.classList.contains('is-open')) {
        _this.elem.classList.remove('is-open')
    } else {
        closeAllDropdown()
        _this.elem.classList.add('is-open')
        _this.dropdownSearch.focus()
    }
}

// Close all open dropdown
function closeAllDropdown() {
    _this.dropjs.forEach(function(dropdown) {
        dropdown.classList.remove('is-open')
    })
}


function generateOptions() {
    // If '_this.optionsStatus' false
    if(_this.optionsStatus === false) {
        _this.setOptionsTemp = []
        _this.dropdownLI.forEach(function(item, index) {
            // Execute in first loop to pass in all option attributes
            (index === 0) ? _this.dropdownLIAttrbs = item.attributes : ''
            // store all current dropdown items to temporay array var
            _this.setOptionsTemp[index] = item
            // store all current dropdown innerText items to temporay array var
            _this.setOptionsTextTemp[index] = item.innerText
        })
        // Pass in to another array var, this var will have the original dropdown values
        _this.optionsDef = _this.setOptionsTemp
        // set to TRUE
        _this.optionsStatus = true
    }
}

function updateScrollView() {
    let currentScrollView = _this.dropdownUL
    let parentViewHeight = currentScrollView.clientHeight
    let listItemHeight = _this.dropdownUL.querySelector('li').clientHeight
    let listActiveItem = _this.activeLI
    let distanceVert = Math.abs(currentScrollView.getBoundingClientRect().top - listActiveItem.getBoundingClientRect().top)
    // Scroll up
    if( distanceVert > (parentViewHeight - listItemHeight)) {
        currentScrollView.scrollTop += listItemHeight
    }
    // Scroll down
    else if( distanceVert < listItemHeight / 2) {
        currentScrollView.scrollTop -= listItemHeight
    }
}

function optionHighlightKey(event) {
    // `Up` key
    if (event.which === 40) {
        _this.activeLI.classList.remove('is-selected')
        _this.activeLI = _this.activeLI.nextElementSibling || _this.activeLI
        if( !_this.activeLI.classList.contains('no-results') ) {
            _this.activeLI.classList.add('is-selected')
        }
        updateScrollView()
    }
    // `Down` key
    else if (event.which === 38) {
        _this.activeLI.classList.remove('is-selected')
        _this.activeLI = _this.activeLI.previousElementSibling || _this.activeLI
        if( !_this.activeLI.classList.contains('no-results') ) {
            _this.activeLI.classList.add('is-selected')
        }
        updateScrollView()
    }
    // `Enter/Return` key
    else if ( event.which === 13 ) {
        updateDropdownVal()
        dropdownToggle()
    }
}

function renderOptions(listsOpt) {
    // set list empty to avoid undefined error
    let list = ''
    // loop through each list
    listsOpt.forEach(function(li) {
        // append each string tag item to `list` variable
        list += li.outerHTML
    })
    // Execute when nothing found to generate `No Results Found` item
    if(listsOpt.length === 0) {
        // create <li> element
        let li = document.createElement('li')
        // Loop through `_this.dropdownLIAttrbs` to generate it's attribute based on it's original DOM attributes
        for( var i = 0; _this.dropdownLIAttrbs.length > i; i++) {
            // Set each attribute
            li.setAttribute(_this.dropdownLIAttrbs[i].name, _this.dropdownLIAttrbs[i].value)
        }
        // add class `no-results`
        li.classList.add('no-results')
        // remove the `data-value` attr
        li.removeAttribute('data-value')
        // add text
        li.innerText = 'No results found'
        // append li string to `list` variable
        list += li.outerHTML
    }
    // Append to DOM
    _this.dropdownUL.innerHTML = list
    // update selected option
    setSelectedOption()
}

// This will render Default dropdown values of the previous dropdown
function renderOptionsDef() {
    // set list empty to avoid undefined error
    let list = ''
    // loop through each list
    _this.optionsDef.forEach(function(item) {
        // append each string tag item to `list` variable
        list += item.outerHTML
    })
    // Append to DOM
    _this.dropdownUL.innerHTML = list
}

function filterOption() {
    let filteredOptions = []
    // set the input keyword to lowercase
    let keyword = _this.dropdownSearch.value.toLowerCase()
    // Filter each value inside `_this.setOptionsTextTemp` based on the keyword input
    let filteredTextOptions = _this.setOptionsTextTemp.filter(function(option) {
        // set filted value to lowercase
        option = option.toLowerCase()
        // return the value
        return option.indexOf(keyword) > -1
    })

    // Loop through the filtered text array variable
    for( var i = 0; filteredTextOptions.length > i; i++ ) {
        // Loop through the dropdown items tags
        for(var j = 0; _this.setOptionsTemp.length > j; j++) {
            // set var for innerText
            let innerTextOpt = _this.setOptionsTemp[j].innerText
            // Compare if match
            if(filteredTextOptions[i].toLowerCase() === innerTextOpt.toLowerCase()) {
                // Push the found item
                filteredOptions.push(_this.setOptionsTemp[j])
            }
        }
    }
    // Render the items found in html tag
    renderOptions(filteredOptions)
    // set new values for the dropdown
    updateElements()
}

function init() {
    dropjs()
}

export default init
