// ----------------
// Product Listing Page Module
// ---------------------------------------------------------------
// import filterDropdowns from './filterDropdowns'
import controller from './controller'

function check() {
    return !!document.getElementById('page-product-listing')
}

function init() {
    if (!check()) return
    controller()
}

export default init
