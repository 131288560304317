var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "finishes-slider" }, [
    _vm.images.length > 1 && _vm.buttons
      ? _c(
          "div",
          { staticClass: "prev-button badge-circle", on: { click: _vm.prev } },
          [_c("span", { staticClass: "caret caret--left" })]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "image-list" },
      _vm._l(_vm.images, function(img) {
        return _c("div", { class: "slider-slide " + _vm.bringToTop(img.id) }, [
          _c("div", { staticClass: "slide-content", attrs: { id: img.id } }, [
            _c("div", { staticClass: "slide-background--blur-container" }, [
              _c("div", {
                staticClass: "slide-background--blur",
                style: _vm.backgroundImg(img.url)
              })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "slide-background",
                style: _vm.backgroundImg(img.url),
                attrs: { alt: img.alt },
                on: {
                  click: function($event) {
                    return _vm.onSlideClick(img.href)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "slide-link background-pattern--one background-color--light",
                    class: _vm.bringToTop(img.id)
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button primary has-caret--right",
                        attrs: { href: img.sampleUrl }
                      },
                      [
                        _vm._v("REQUEST A SAMPLE "),
                        _c("i", { staticClass: "arrow right" })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: "slide-caption " + _vm.hideIfInactive(img.id) },
              [
                _c("p", [
                  _c("b", [
                    _vm._v("Photo Credit: "),
                    _c("a", { attrs: { href: img.href, target: "_blank" } }, [
                      _vm._v(_vm._s(img.credit))
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(img.description))])
              ]
            )
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm.images.length > 1 && _vm.buttons
      ? _c(
          "div",
          { staticClass: "next-button badge-circle", on: { click: _vm.next } },
          [_c("span", { staticClass: "caret caret--right" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }