var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carousel-panel" }, [
    _vm.hasPrev && _vm.buttons
      ? _c(
          "div",
          { staticClass: "prev-button badge-circle", on: { click: _vm.prev } },
          [_c("span", { staticClass: "caret caret--left" })]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "carousel-container" },
      _vm._l(_vm.filteredCovers, function(cover, idx) {
        return _c(
          "a",
          {
            class: "carousel-card " + _vm.getCardClass(idx),
            attrs: { href: cover.url, target: "_blank" }
          },
          [
            _c("div", [
              _c("img", {
                staticClass: "carousel-card--img",
                attrs: { src: cover.imageUrl, alt: cover.title }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "carousel-card--model-number" }, [
                _vm._v(_vm._s(cover.modelNumber))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "carousel-card--title" }, [
                _vm._v(_vm._s(cover.title))
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.hasNext && _vm.buttons
      ? _c(
          "div",
          { staticClass: "next-button badge-circle", on: { click: _vm.next } },
          [_c("span", { staticClass: "caret caret--right" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }