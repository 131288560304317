<template>
    <div class="carousel-panel">
        <div v-if="hasPrev && buttons" class="prev-button badge-circle" @click="prev">
            <span class="caret caret--left"></span>
        </div>
        <div class="carousel-container">
            <a :class="'carousel-card '+getCardClass(idx)" v-for="(finish, idx) in filteredFinishes"
               :href="finish.url" target="_blank">
              <div>
                <img class="carousel-card--img" :src="finish.imageUrl" :alt="finish.title">
                <div class="carousel-card--model-number">{{ finish.modelNumber }}</div>
                <div class="carousel-card--title">{{ finish.title }}</div>
              </div>
            </a>
        </div>
        <div v-if="hasNext && buttons" class="next-button badge-circle" @click="next">
            <span class="caret caret--right"></span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "product-finish-carousel",
        data() {
            return {
                // carousel properties
                buttons: true,
                buttonsSkip: 0,
                largeCards: 6,
                largeBp: 1300,
                mediumCards: 4,
                mediumBp: 1000,
                smallCards: 3,
                smallBp: 685,
                xsmallCards: 2,

                // carousel state
                firstCard: 0,
                filterPriceGroupId: null,
                windowWidth: window.innerWidth
            }
        },

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            })
        },
        computed: {
            ...mapGetters(['finishes']),
            hasPrev() {
                return this.firstCard > 0
            },
            hasNext() {
                return this.firstCard + this.carouselSize < this.filteredFinishes.length
            },
            filteredFinishes() {
                if (this.currentPriceGroup !== null) {
                    return this.finishes.filter(x => x.priceGroupId === this.currentPriceGroup)
                }
                return this.finishes
            },
            carouselSize() {
                // change based on window size...
                if (this.windowWidth >= this.largeBp) {
                    return this.largeCards
                } else if (this.windowWidth >= this.mediumBp) {
                    return this.mediumCards
                } else if (this.windowWidth >= this.smallBp) {
                    return this.smallCards
                } else {
                    return this.xsmallCards
                }
            }
        },
        methods: {
            prev() {
                if (this.hasPrev) {
                    this.firstCard = this.firstCard - 1
                }
            },
            next() {
                if (this.hasNext) {
                    this.firstCard = this.firstCard + 1
                }
            },
            getCardClass(idx){
                if (idx >= this.firstCard && idx < this.firstCard + this.carouselSize) {
                    return 'active'
                }
                return ''
            },
            backgroundImg(imgSrc) {
                return 'background-image: url(\''+imgSrc+'\');'
            },
            onClick(id) {
                this.$emit('select', id)
            },
            onResize() {
                this.windowWidth = window.innerWidth
            }
        },
        props: {
            currentPriceGroup: {
            }
        }
    }
</script>

<style lang="scss" scoped>
  .carousel-panel {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 80px 15px;
    position: relative;
  }
  div.carousel-container {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: auto;
    overflow: hidden;

    .carousel-card {
      border: 3px solid rgba(0, 0, 0, 0);

      display: none;
      &.active {
        display: inline-block;
      }

      width: 150px;
      height: 200px;
      margin: 15px;

      letter-spacing: 1px;
      color: #5c5c5c;
      &--img {
        margin-bottom: 10px;
      }
      &--model-number {
        font-family: "Dia Bold", sans-serif;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
      &--title {
        font-family: 'Dia Regular', sans-serif;
        text-transform: uppercase;
        font-size: 0.75rem;
      }
    }
  }

  .prev-button, .next-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    background-color: #e3e3e3;
    cursor: pointer;
    z-index: 100;
  }
  .prev-button {
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    .caret {
      border-color: transparent grey transparent transparent;
    }
  }
  .next-button {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    .caret {
      border-color: transparent transparent transparent grey;
    }
  }
</style>