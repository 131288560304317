<template>
  <div class="finishes-detail">
    <div class="background-pattern--one background-color--light">
      <div class="grid-container padding-3">
        <image-slider :current-slide="currentSlide" @select="onSliderSelect" @change="onSlideChange"/>
      </div>
    </div>
    <div class="grid-container padding-3">
      <image-carousel :current-slide="currentSlide" @select="onCarouselSelect"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ImageSlider from "./components/image-slider.vue";
import ImageCarousel from "./components/image-carousel.vue";

export default {
  components: {
    ImageCarousel,
    ImageSlider,
  },
  data() {
    return {
      currentSlide: null
    }
  },
  name: "app",
  mounted() {
    this.currentSlide = this.images ? this.images[0]['id'] : null
  },
  computed: {
    ...mapGetters(['images']),
    displayStyle() {
      return 'display:' + (this.modalVisible ? 'block' : 'none')
    }
  },
  methods: {
    onSlideChange(id) {
      this.currentSlide = id
    },
    onSliderSelect(id) {
      this.currentSlide = id
    },
    onCarouselSelect(id) {
      this.currentSlide = id
    }
  }
}
</script>