/**
 * Loading functions for product listing
 */

import Loader from '../../../lib/venveo-loader'
import * as events from '../../../lib/venveo-loader/events'

import $ from 'jquery'
import debounce from 'lodash-es/debounce'

let productLoader = new Loader()
let pageNumber = (new URL(document.location)).searchParams.get('page') || 1

const loadingEl = $('<div style="width: 100%; text-align: center;">Loading...</div>')
const noResultsEl = $('<div style="width: 100%; text-align: center;"><p>Oops! It doesn\'t look like there are any products in this category.</p></div>')

const config = {
    url: $('[data-loader-url]').data('loaderUrl'),
    $filters: $('[data-filter]'),
    $scrollTarget: $('#scroll-target'),
    itemTemplates: {
        main: $('#tpl-item-small').html()
    },
    container: $('#product-listing-grid'),
}

const loaderSettings = {
    url: config.url,
    itemTemplates: config.itemTemplates,
    itemTemplateSelector: (() => {
        return 'main'
    }),
    container: config.container,
    params: {},
    initialPages: pageNumber
}

function complete({ detail }) {
    loadingEl.detach()
    if (!productLoader.onLastPage()) {
        _bindScrollEvent()
    }
    // 'detail' is the data that gets attached to the CustomEvent.
    if (detail.data.meta) {
        updateFilterDropdown(detail.data.meta)
    }
}

function error() {
    noResultsEl.text('There was an error, please try again later or contact us.')
    noResultsEl.appendTo(config.container)
}

function loading() {
    _unbindScrollEvent()
    noResultsEl.detach()
    updateCategoryLinks()
    loadingEl.appendTo(config.container)
}

function updateCategoryLinks() {
    let $links = $('a[data-update-queries]')

    let data = []
    config.$filters.each((index, filter) => {
        const $filter = $(filter)
        const filterName = $filter.data('filter')
        data[filterName] = $filter.val()
    })

    $links.each((index, link) => {
        let $link = $(link)
        const url = new URL($link.attr('href'))

        Object.keys(data).map(key => {
            if (data[key] === '*' || data[key] === '') {
                url.searchParams.delete(key)
            } else {
                url.searchParams.set(key, data[key])
            }
        })
        $link.attr('href', url.href)
    })
}

function lastPage() {
    _unbindScrollEvent()
}

function noItems() {
    _unbindScrollEvent()
    noResultsEl.appendTo(config.container)
    // config.$noResults.show()
    // config.$loader.hide()
}

/**
 * Functions for FILTERING
 */

// This updates the url param
function updateUrlParams(params) {
    const url = new URL(window.location.href)
    const names = Object.getOwnPropertyNames(params)
    for(let i = 0; i < names.length; i++) {
        if (params[names[i]] === '*') {
            url.searchParams.delete(names[i])
        } else {
            url.searchParams.set(names[i], params[names[i]])
        }
    }
    history.pushState({ scrollY: window.pageYOffset }, document.title, url)
}

// Event listeners for filter dropdowns
function _bindFilterListeners(loader) {
    config.$filters.on('change', (e) => handleFilterChange(loader, e))
}

function handleFilterChange(loader) {
    getFilteredItems(loader, getFilterValues())
}

function getFilterValues() {
    let data = {}
    config.$filters.each((index, filter) => {
        const $filter = $(filter)
        const filterName = $filter.data('filter')
        data[filterName] = $filter.val()
    })
    if ( window.productCategory ) {
        data['category'] = window.productCategory
    }
    return data
}

function updateFilterDropdown({ ranges }) {
    if (ranges.length) {
        const rangeFilter = $('[data-filter="range"]'),
            rangeValue = rangeFilter.val()

        rangeFilter.empty()
        const baseOption = $('<option></option>').attr('value', '').text('ALL RANGES')
        rangeFilter.append(baseOption) // This is the 'ALL RANGES' option, otherwise it gets cleared out
        if (rangeValue === '') baseOption.attr('selected', true)
        // Append all the rest of the options
        ranges.forEach(range => {
            const option = $('<option></option>').attr('value', range.id).text(range.title.toUpperCase())
            if (range.id === rangeValue) option.attr('selected', true)
            rangeFilter.append(option)
        })
    }
}

// This gets new filtered items
function getFilteredItems(loader, params) {
    // Settings
    let newSettings = {}
    for(var param in params) {
        newSettings[param] = params[param]
    }
    loader.clearAndUpdateParams(newSettings)
    // Actions
    // This needs to get the actual params object, not the newSettings object!
    updateUrlParams(params)
    loader.getMoreItems()
}

function isScrolledIntoView($elem) {
    var docViewTop = $(window).scrollTop()
    var docViewBottom = docViewTop + $(window).height()

    var elemTop = $elem.offset().top
    var elemBottom = elemTop + $elem.height()

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop))
}

function changeState(params) {
    const url = new URL(window.location.href)
    const names = Object.getOwnPropertyNames(params)
    for(let i = 0; i < names.length; i++) {
        if (params[names[i]] === '*') {
            url.searchParams.delete(names[i])
        } else {
            url.searchParams.set(names[i], params[names[i]])
        }
    }
    history.replaceState({ scrollY: window.pageYOffset }, document.title, url)
}

/**
 * Register the scroll event handler
 * @private
 */
function _bindScrollEvent() {
    $(window).scroll(debounce(() => {
        if (isScrolledIntoView(config.$scrollTarget)) {
            loadingEl.prependTo(config.container)
            productLoader.getMoreItems()
            console.log('loading another page...')
            changeState({page:++pageNumber})
        } else {
            // update the scroll position
            const url = new URL(window.location.href)
            history.replaceState({ scrollY: window.pageYOffset }, document.title, url)
        }
    }, 250, {leading: true})
    )
}

function _unbindScrollEvent() {
    $(window).unbind('scroll')
}

/**
 * The INIT function, where all the magic starts.
 */

export default function init() {
    productLoader.on(events.EVENT_LOADING, e => loading(e))
    productLoader.on(events.EVENT_DONE, e => complete(e))
    productLoader.on(events.EVENT_NO_DATA, e => noItems(e))
    productLoader.addEventListener(events.EVENT_LAST_PAGE, e => lastPage(e))
    productLoader.addEventListener(events.EVENT_ERROR, error)
    loaderSettings.params = getFilterValues()
    productLoader.init(loaderSettings)

    // load initial pages and then scroll to saved position (if exists)
    productLoader.loadInitialPages(function () {
        if (window.history.state !== null && 'scrollY' in window.history.state) {
            window.scrollTo(0,window.history.state.scrollY)
        }
    })



    _bindFilterListeners(productLoader)
}
