const QRCode = require('qrcode')

const augmentedRealityLinks = function () {
    const tabViews = document.querySelectorAll('.product-tab-container[data-tabs]')
    Object.keys(tabViews).forEach(function (k) {
        const container = tabViews[k]

        // get all the tab content
        const tabContent = container.querySelectorAll('[data-tab-content]')

        // if only one tab (i.e. no AR), don't do anything
        if (tabContent.length <= 1) {
            return;
        }

        if (window.innerWidth >= 1000) {
            // add tabs only on desktop browsers and only if multiple tabs exist

            const tabBar = document.createElement('div')
            tabBar.classList.add('product-tab-bar')

            // append tab bar to tab container
            container.appendChild(tabBar)

            // store first tab dimensions so all tab content match in dimensions
            const tabWidth = tabContent[0].clientWidth
            const tabHeight = tabContent[0].clientHeight

            // add tab links to each content pane
            Object.keys(tabContent).forEach(function (i) {
                const el = tabContent[i]
                const tabLabel = el.getAttribute('data-tab-label')
                const tabSlug = el.getAttribute('data-tab-slug')

                // set dimensions to match first tab
                el.style.width = tabWidth + 'px'
                el.style.height = tabHeight + 'px'

                // set tab link properties and styles
                const tabDiv = document.createElement('div')
                tabDiv.classList.add('product-tab-link')
                tabDiv.setAttribute('data-tab-link', null)
                tabDiv.setAttribute('data-linked-tab-slug', tabSlug)
                tabDiv.innerHTML = tabLabel

                // add event handler to change linked content panes
                tabDiv.addEventListener('click', () => {
                    Object.keys(tabContent).forEach((j) => {
                        // hide all tab content
                        const tab = tabContent[j].style.display = 'none'
                    })
                })

                // append tab link div to the tab bar
                tabBar.appendChild(tabDiv)
            })

            // go back through and add event listeners
            const tabLinks = container.querySelectorAll('[data-linked-tab-slug]')
            Object.keys(tabLinks).forEach((j) => {
                const tabLink = tabLinks[j]
                const slug = tabLink.getAttribute('data-linked-tab-slug')

                tabLink.addEventListener('click', () => {
                    // grey out all tab links
                    Object.keys(tabLinks).forEach(x => tabLinks[x].classList.remove('active'))

                    // highlight active tab link
                    tabLink.classList.add('active')

                    // hide all tab content
                    Object.keys(tabContent).forEach(x => tabContent[x].style.display = 'none')

                    // show active tab content
                    const matchedTabs = container.querySelectorAll(`[data-tab-slug="${slug}"]`)
                    if (!!matchedTabs && matchedTabs.length > 0) {
                        matchedTabs[0].style.display = 'block'
                    }
                })
            })


            // set first tab as active
            Object.keys(tabContent).forEach(x => tabContent[x].style.display = 'none')
            tabContent[0].style.display = 'block'

            tabLinks[0].classList.add('active')
        } else {
            // add a 'view in room' button for mobile
            const viewInRoomButton = document.createElement('a')
            viewInRoomButton.className = 'button small letter-spacing-2 margin-top-1'
            viewInRoomButton.innerHTML = 'View In Room'
            viewInRoomButton.href = container.getAttribute('data-ar-link')

            // append tab bar to tab container
            container.appendChild(viewInRoomButton)
        }
    })
}

const iconLinks = function () {
    // only use icon direct links for mobile
    if (window.innerWidth >= 1000) {
        const iconLinks = document.querySelectorAll('.product-tab-container [data-icon-linked-tab-slug]')
        const tabLinks = document.querySelectorAll('.product-tab-container [data-linked-tab-slug]')
        const tabContent = document.querySelectorAll('.product-tab-container [data-tab-content]')
        Object.keys(iconLinks).forEach((j) => {
            const iconLink = iconLinks[j]
            const slug = iconLink.getAttribute('data-icon-linked-tab-slug')

            iconLink.addEventListener('click', () => {
                // grey out all tab links
                Object.keys(tabLinks).forEach(x => tabLinks[x].classList.remove('active'))

                const tabLink = document.querySelector(`.product-tab-container [data-linked-tab-slug="${slug}"]`)

                // highlight active tab link
                tabLink.classList.add('active')

                // hide all tab content
                Object.keys(tabContent).forEach(x => tabContent[x].style.display = 'none')

                // show active tab content
                const matchedTabs = document.querySelectorAll(`.product-tab-container [data-tab-slug="${slug}"]`)
                if (!!matchedTabs && matchedTabs.length > 0) {
                    matchedTabs[0].style.display = 'block'
                }
            })
        })
    } else {
        const iconLinks = document.querySelectorAll('.product-tab-container [data-mobile-direct-link]')
        Object.keys(iconLinks).forEach(function (k) {
            const el = iconLinks[k]
            const url = el.getAttribute('data-mobile-direct-link')
            if (!!url) {
                el.addEventListener('click', () => {
                    console.log('navigating to mobile link')
                    window.location = url
                })
            }
        })
    }
}

const buildQRCodes = function () {
    const qrCodes = document.querySelectorAll('[data-qr-code]')
    Object.keys(qrCodes).forEach(function (k) {
        const canvas = document.createElement('canvas')
        QRCode.toCanvas(canvas, qrCodes[k].getAttribute('data-qr-code'))
        qrCodes[k].appendChild(canvas)
    })
}

const init = function(){
    augmentedRealityLinks()
    buildQRCodes()
    iconLinks()
}

export default init