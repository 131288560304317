<template>
    <div class="price-group-tabs">
      <div class="price-group-tabs--container">
        <div :class="'price-group-tabs--tab '+getClass(null)" @click="onCoverGroupClick(null)">
          Show All
        </div>
        <div v-for="coverGroup in coverGroups"
             :class="'price-group-tabs--tab '+getClass(coverGroup.id)"
             @click="onCoverGroupClick(coverGroup.id)">
          {{ coverGroup.title }}
        </div>
      </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "product-cover-groups",
        computed: {
            ...mapGetters(['coverGroups', 'autoScroll', 'scrollSpeed']),
        },
        methods: {
            getClass(coverGroupId) {
                if (this.currentCoverGroup === coverGroupId) {
                    return 'active'
                }
                return ''
            },
            onCoverGroupClick(e) {
                this.$emit('change', e)
            }
        },
        props: {
            currentCoverGroup: {
            }
        }
    }
</script>

<style lang="scss" scoped>
  .price-group-tabs {
    display: block;
    margin: auto;
    width: calc(100% - 320px);
    padding: 10px;

    @media screen and (max-width: 800px){
      width: 100%;
    }
    &--container {
      display: flex;
      justify-content: space-between;
      padding: 15px;
    }
    &--tab {
      font-family: "Dia Bold", sans-serif;
      font-size: 0.85rem;
      text-transform: uppercase;
      padding: 4px;
      cursor: pointer;

      color: #aaaaaa;
      &.active {
        color: #1b6f9f;
      }
    }
  }
</style>