import $ from 'jquery'
require('waypoints/lib/noframework.waypoints')
require('waypoints/lib/shortcuts/inview')

function scrollTriggerAnimation(){
    window.addEventListener('load', function(){
        // On-scroll and on-view trigger animation
        if( $('[data-animate]').length ) {
            $('[data-animate]').each(function () {
                new Waypoint.Inview({
                    element: this,
                    enter: function() {
                        $(this.element).addClass('show').removeAttr('data-animate')
                        $(this).delay(2000).queue(function () {
                            $(this.element).removeClass('animate')
                        })
                    }
                })
            })
        }
    })
}

function scrollToTop() {
    $('[data-scroll-top]').on('click', function(e) {
        $('html,body').animate({ scrollTop: 0 }, 'fast')
        return false
    })
}

function init() {
    scrollTriggerAnimation()
    scrollToTop()
}

export default init
