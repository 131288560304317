var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "price-group-tabs" }, [
    _c(
      "div",
      { staticClass: "price-group-tabs--container" },
      [
        _c(
          "div",
          {
            class: "price-group-tabs--tab " + _vm.getClass(null),
            on: {
              click: function($event) {
                return _vm.onCoverGroupClick(null)
              }
            }
          },
          [_vm._v("\n      Show All\n    ")]
        ),
        _vm._v(" "),
        _vm._l(_vm.coverGroups, function(coverGroup) {
          return _c(
            "div",
            {
              class: "price-group-tabs--tab " + _vm.getClass(coverGroup.id),
              on: {
                click: function($event) {
                  return _vm.onCoverGroupClick(coverGroup.id)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(coverGroup.title) + "\n    ")]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }