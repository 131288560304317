import $ from 'jquery'
import debounce from 'lodash-es/debounce'

const UI = {
    $rangeSelector: $('#range-selector')
}

function setupEventHandlers() {
    const debouncePeriod = 250
    UI.$rangeSelector.on('change', debounce(handleRangeChange, debouncePeriod))
}

function setSelectedRangeVal() {
    UI.$rangeSelector.val(window.entrySlug)
}

function handleRangeChange() {
    const $selected = $(this).find('option:selected')
    const url = $selected.data('url')
    window.location.href = url

}

function init() {
    setSelectedRangeVal()
    setupEventHandlers()
}

export default init
