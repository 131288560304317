import $ from 'jquery'
import * as components from './components'
import IntersectionObserver from 'intersection-observer' //IntersectionObserver polyfill
import 'custom-event-polyfill'
import 'url-polyfill'

import '../sass/app.scss'

import './static-assets'

import './lib/foundation-explicit-pieces'

import modal from './venveo-modal'
import slider from './venveo-slider'
import finishSlider from './finishes-detail'
import productFinishSlider from './product-finishes-slider'

window.$ = $
window.jQuery = $

const setupPages = (function() {
    components.siteUI()
    components.pagesJS()
    modal({images: window.modalImgs})
    slider({images: window.sliderImgs})
    finishSlider({finish: window.finish})
    productFinishSlider({
        finishes: window.finishes,
        priceGroups: window.priceGroups,
        covers: window.covers,
        coverGroups: window.coverGroups,
        isElementsProduct: window.isElementsProduct,
        isHandleStylesProduct: window.isHandleStylesProduct,
        defaultHandleDescription: window.defaultHandleDescription
    })
})()

const mainInit = (function() {
    setupPages
})()

function Main() {
    this.init = function() {
        $(document).ready(mainInit)
        $(document).foundation()
    }
    return {
        init: this.init
    }
}

const main = new Main()
main.init()