var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-finish-app" },
    [
      _c("product-finish-tab", {
        attrs: {
          title: "Explore Finishes",
          "is-active": _vm.finishTabActive || !_vm.hasExtraTab,
          "current-price-group": _vm.currentPriceGroup
        },
        on: { click: _vm.onFinishTabClick }
      }),
      _vm._v(" "),
      _vm.hasExtraTab
        ? _c("product-finish-tab", {
            attrs: {
              title: _vm.isElementsProduct
                ? "Explore Covers"
                : "Explore Handles",
              "is-active": !_vm.finishTabActive,
              "current-price-group": _vm.currentPriceGroup
            },
            on: { click: _vm.onCoversTabClick }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.finishTabActive || !_vm.hasExtraTab
        ? _c(
            "div",
            {
              staticClass: "product-finish-app--content background-color--light"
            },
            [
              _c("product-finish-groups", {
                attrs: { "current-price-group": _vm.currentPriceGroup },
                on: { change: _vm.onPriceGroupChange }
              }),
              _vm._v(" "),
              _c("product-finish-pricing", {
                attrs: {
                  price: _vm.price,
                  "selected-price-group-name": _vm.priceGroupName
                }
              }),
              _vm._v(" "),
              _c("product-finish-carousel", {
                attrs: { "current-price-group": _vm.currentPriceGroup }
              })
            ],
            1
          )
        : !_vm.finishTabActive
        ? _c(
            "div",
            {
              staticClass: "product-finish-app--content background-color--light"
            },
            [
              _c("product-cover-groups", {
                attrs: { "current-cover-group": _vm.currentCoverGroup },
                on: { change: _vm.onCoverGroupChange }
              }),
              _vm._v(" "),
              _vm.currentCoverGroup
                ? _c("div", { staticClass: "product-cover-description" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.coverGroup.description) + "\n    "
                    )
                  ])
                : _vm.defaultHandleDescription
                ? _c("div", { staticClass: "product-cover-description" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.defaultHandleDescription) +
                        "\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("product-cover-carousel", {
                attrs: { "current-cover-group": _vm.currentCoverGroup }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }