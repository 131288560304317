
import checkoutController from './checkout'

function checkoutCheck() {
    return !!document.getElementById('store-checkout')
}

function init() {
    if (checkoutCheck()) {
        checkoutController()
    }
}

export default init
