import * as types from './mutation-types'
const mutations = {
    [types.SHOW_MODAL](state, data) {
        state.selectedImage = data
        state.modalVisible = true
    },
    [types.HIDE_MODAL](state) {
        state.modalVisible = false
    },
    [types.LOADING_INC](state) {
        state.loadingProcesses += 1
    },
    [types.LOADING_DEC](state) {
        state.loadingProcesses -= 1
    },
}

export default mutations