<template>
    <div class="carousel-container grid-x grid-margin-x small-up-2 medium-up-4 large-up-5">
        <div v-for="img in images"
             class="cell carousel-img"
             :class="{'active': currentSlide === img.id}"
             :style="backgroundImg(img.url)"
             @click="onClick(img.id)"></div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "image-carousel",
        computed: {
            ...mapGetters(['images']),
        },
        methods: {
            backgroundImg(imgSrc) {
                return 'background-image: url(\''+imgSrc+'\');'
            },
            onClick(id) {
                this.$emit('select', id)
            }
        },
        props: {
            currentSlide: {
                type: String
            }
        }
    }
</script>
