import MmenuLight from 'mmenu-light';

function init(){
    if( document.querySelector('#ham-menu-nav') ) {
        const menu = new MmenuLight(
            document.querySelector( '#ham-menu-nav' ),
            // "(max-width: 1023px)"
        );

        const navigator = menu.navigation({
            // options
            // Default options ['light', 'dark']
            theme: 'dark'
        });

        const drawer = menu.offcanvas({
            // options
            // position: 'right'
        });

        document.querySelector( '[data-ham-menu-trigger]' )
        .addEventListener( "click", ( event ) => {
            event.preventDefault();
            drawer.open();
        });
    }
}

export default init
