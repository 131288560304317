// ----------------
// Gallery Page Module
// ----------------
// Calling this module will set up a Loader instance and functions
// for the blog page only.
// ---------------------------------------------------------------
import rangesController from './ranges'
// import galleryModal from './galleryModal'

function check() {
    return !!document.getElementById('page-range-detail')
}

function init() {
    if (!check()) return
    rangesController()
    // galleryModal()
    // gallery filtering
    // gallery whatever else
}

export default init
