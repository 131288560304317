import $ from 'jquery'
import debounce from 'lodash-es/debounce'

const clientKey = window.authorizeClientKey //'your-test-client-key'
const apiLoginId = window.authorizeApiLoginId //'your-test-client-key'
const stageMap = {0:1,1:2,2:2,3:3} // step index -> stage index
const states = window.commerceStatesList
const shippingAddress = window.commerceShippingAddress
const debouncePeriod = 250
const debugMode = false
let errors = window.flashError

const UI = {
    $addressCountry: $('select.address-country'),
    $addressState: $('select.address-stateId'),
    $billingAddressForm: $('form#billingAddressForm'),
    $stages: $('.e-checkout-stage--item'),

    $removeButton: $('[data-remove-cartitem]'),
    $formSubmit: $('[data-form-submit]'),
    $updateCartAjax: $('[data-update-cart]'),
    $validatePayment: $('[data-validate-payment]'),
    $placeOrder: $('[data-place-order]'),
    $acceptTerms: $('[data-accept-terms]'),
    $errorDisplay: $('[data-display-errors]'),
    $stepNav: $('[data-step-nav]')
}

const forms = {
    billingAddress: document.getElementById('billingAddressForm'),
    payment: document.getElementById('paymentForm'),
    $billing: $('#billingAddressForm')
}

const billing = {
    $addressInputs: forms.$billing.find('input:not([type=hidden]):not(#billingSameAsShipping)'),
    $addressDropdowns: forms.$billing.find('select'),
    $addressLabels: forms.$billing.find('label:not([for=billingSameAsShipping])'),
    $sameAsShipping: $('input#billingSameAsShipping')
}

// ----------- FORM STAGES -----------
let _step = 0
function nextStep() {
    if (debugMode) {
        console.log(`nextStep()`)
        console.log(`_step = ${_step}`)
    }
    // hide all current step
    $('[data-checkout-step]').hide()

    // hide all errors if any are shown
    UI.$errorDisplay.hide()

    // increment step and then show
    _step++
    $(`[data-checkout-step="${_step}"]`).show()
    $('body, html').animate({
        scrollTop: $('#checkout-window').offset().top - 100
    } /* speed */ )
    setActiveStage(stageMap[_step])
    setReactiveQueryString(`step=${_step}`)
}
function goToStep(newStep, pushState=false) {
    if (debugMode) {
        console.log(`goToStep(${newStep})`)
        console.log(`_step = ${_step}`)
    }
    // hide all current step
    $('[data-checkout-step]').hide()

    // increment step and then show
    _step = newStep
    $(`[data-checkout-step="${_step}"]`).show()
    setActiveStage(stageMap[_step])
    if (pushState) {
        setReactiveQueryString(`step=${_step}`)
    }

    // re-enable all fields
    enableInputFields()

    // reset field states
    handleSameAsShippingChange()
}

function $getFormForStep() {
    return $(`[data-checkout-step-form="${_step}"]`)
}

function setActiveStage(index) {
    UI.$stages.removeClass('is-active')
    UI.$stages.eq(index).addClass('is-active')
}

function setReactiveQueryString(str) {
    // use pushState rather than modifying window.location.href so that back/forward buttons work with checkout
    // history.pushState({}, 'Checkout', str)
    const p_i = (window.location.href).indexOf('#')
    if (p_i >= 0) {
        const reactive = (window.location.href).substring(p_i)
        const q_i = reactive.indexOf('?') // get index of '?' after the '#' symbol
        if (q_i >= 0) {
            // replace query string
            if (debugMode) console.log(`pushing to state: "${str}"`);
            history.pushState({}, 'Checkout', (window.location.href).substring(0, p_i+q_i) + '?' + str)
        } else {
            // no query string
            // window.location.href = window.location.href + '?' + str
            if (debugMode) console.log(`pushing to state: "${str}"`);
            history.pushState({}, 'Checkout', window.location.href + '?' + str)
        }
    } else {
        // no '#' symbol in URI
        // window.location.href = window.location.href + '#?' + str
        if (debugMode) console.log(`pushing to state: "${str}"`);
        history.pushState({}, 'Checkout', window.location.href + '#?' + str)
    }
}


// ----------- FORM INPUTS -----------

function disableInputFields() {
    const $form = $getFormForStep()
    $form.find('input').attr('disabled','disabled')
    $form.find('select').attr('disabled','disabled')
}

function enableInputFields() {
    const $form = $getFormForStep()
    $form.find('input').removeAttr('disabled')
    $form.find('select').removeAttr('disabled')
}


// ----------- WORLDPAY -----------

function setupAuthorize() {

    if (typeof Accept !== 'undefined') {
        window.onload = function () {
            let authData = {}
            authData.clientKey = clientKey
            authData.apiLoginID = apiLoginId
            let cardData = {};
            $("ul").find(`[data-slide='${current}']`)
            cardData.cardNumber = $('[data-authorize=\'number\']').value
            cardData.month = $('[data-authorize=\'exp-month\']').value
            cardData.year = $('[data-authorize=\'exp-year\']').value
            cardData.cardCode = $('[data-authorize=\'cvc\']').value

            let secureData = {};
            secureData.authData = authData;
            secureData.cardData = cardData;

            Accept.dispatchData(secureData, (response) => {
                if (response.messages.resultCode === "Error") {
                    let i = 0;
                    errors = '';
                    while (i < response.messages.message.length) {
                        console.log(
                            response.messages.message[i].code + ': ' +
                            response.messages.message[i].text
                        );
                        errors += response.messages.message[i].text + ' '
                        i = i + 1;
                    }
                    console.log(`errors: ${errors}`)
                    renderErrorsList()
                } else {
                    let token = response.opaqueData.dataValue
                    $('[data-authorize=\'authorizeNetToken\']').val(token)
                    forms.payment.submit()
                }
            });
        }
    }
}

// ---------- CARD VALIDATION -----------

function validateCardNumber(number) {
    console.log(`validating card number:${number}`)
    const amex = /^(?:3[47][0-9]{13})$/;
    const visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const master = /^(?:5[1-5][0-9]{14})$/;
    const discover = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    const diners = /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/;
    const jcb = /^(?:(?:2131|1800|35\d{3})\d{11})$/;
    const cardTypes = {amex,visa,master,discover,diners,jcb}
    if (number.length) {
        for (let type in cardTypes) {
            if (number.length && number.match(cardTypes[type])) {
                console.log(`card is of type "${type}"`)

                // Check card using the Luhn algorithm
                let nCheck = 0, nDigit = 0, bEven = false;
                const value = number.replace(/\D/g, '');

                for (let n = value.length - 1; n >= 0; n--) {
                    let cDigit = value.charAt(n),
                        nDigit = parseInt(cDigit, 10);

                    if (bEven) {
                        if ((nDigit *= 2) > 9) nDigit -= 9;
                    }

                    nCheck += nDigit;
                    bEven = !bEven;
                }

                return (nCheck % 10) === 0;
            }
        }
    }
    return false
}

function validateExpDate(month, year) {
    const today = new Date()
    const cardDate = new Date(year, month)
    console.log(today, cardDate)
    return today.getTime() < cardDate.getTime()
}


// ----------- EVENT HANDLERS -----------

function setupEventHandlers() {
    UI.$stepNav.on('click', debounce(handleStepNavigate, debouncePeriod))
    UI.$addressCountry.on('change', debounce(handleCountryChange, debouncePeriod))
    UI.$formSubmit.on('click', debounce(handleFormSubmit, debouncePeriod))
    UI.$validatePayment.on('click', debounce(handleValidatePayment, debouncePeriod))
    UI.$updateCartAjax.on('click', debounce(handleStepComplete, debouncePeriod))
    UI.$placeOrder.on('click', debounce(handlePlaceOrder, debouncePeriod))
    UI.$acceptTerms.on('change', debounce(handleAcceptTermsChange, debouncePeriod))
    billing.$sameAsShipping.on('change', debounce(handleSameAsShippingChange, debouncePeriod))
    // setupAuthorize()
    window.onpopstate = function(e) {
        if (debugMode) console.log(e);
        if (getCurrentStep() > 0) {
            if (debugMode) console.log(`step = ${_step}`);
        } else {
            if (debugMode) console.log('back button pressed');
        }
    }
}

function handleStepNavigate() {
    let link = $(this).attr('data-step-nav')
    if (isNaN(link)) {
        // link is not to a step
        window.location.href = link
    } else {
        // link is directly to another step
        goToStep(link,true)
    }
}

function handleValidatePayment(e) {
    e.preventDefault()
    UI.$validatePayment.attr('disabled','disabled')
    disableInputFields()
    const $form = $getFormForStep()
    errors = ''
    let goToNext = false;

    if ($form.length) {
        // validate card number
        const $cardNumbers = $form.find('[data-validate-number]')

        let validCardNumber = false;
        if($cardNumbers.length) {
            validCardNumber = validateCardNumber($cardNumbers[0].value)
        }
        console.log(`validCardNumber = ${validCardNumber}`)
        if (!validCardNumber) {
            errors += 'Invalid card number'
        }

        // validate card expiration date
        const $cardExpMonth = $form.find('[data-validate-exp-month]')
        const $cardExpYear = $form.find('[data-validate-exp-year]')

        let validExp = false;
        if ($cardExpMonth.length && $cardExpYear.length) {
            validExp = validateExpDate($cardExpMonth[0].value, $cardExpYear[0].value)
        }
        console.log(`validExp = ${validExp}`)
        if (!validExp) {
            errors += (errors.length ? '; card is expired (date must be in format MM/YYYY)' : 'Card is expired (date must be in format MM/YYYY)')
        }

        if (validCardNumber && validExp) {
            goToNext = true
        } else {
            renderErrorsList()
        }
    } else {
        console.log('payment validation error')
    }
    enableInputFields()
    UI.$validatePayment.removeAttr('disabled','disabled')

    if (goToNext) {
        nextStep()
    }
}

function handleStepComplete(e) {
    e.preventDefault()
    UI.$updateCartAjax.attr('disabled','disabled')
    const $form = $getFormForStep()

    if ($form.length) {

        // validate to ensure all required input fields have values
        const $inputs = $form.find('input')
        let valid = true
        for (let key in $inputs) {
            if ($inputs[key] instanceof Element) {
                $inputs[key].style = ''
                if (!$inputs[key].disabled && $inputs[key].required && !$inputs[key].value) {
                    // show errors for input element
                    console.log('validation error')

                    $inputs[key].style = 'border-color: red;'

                    valid = false
                }
            }
        }

        // validate to ensure all required select fields have values
        const $selects = $form.find('select')
        for (let key in $selects) {
            if ($selects[key] instanceof Element) {
                $selects[key].style = ''
                if (!$selects[key].disabled && $selects[key].required && !$selects[key].value) {
                    // show errors for input element
                    console.log('validation error')

                    $selects[key].style = 'border-color: red;'

                    valid = false
                }
            }
        }

        // display any validation errors
        if (!valid) {
            const $errorDisplay = $form.find('[data-form-error-display]')
            if ($errorDisplay.length) {
                $errorDisplay[0].innerHTML = 'Required fields are missing'
            }
            UI.$updateCartAjax.removeAttr('disabled','disabled')
            return
        }


        const action = '/' //'/actions/'+$form.find('input[name=action]').val()
        const data = $form.serialize()
        const request = {
            type: 'POST',
            url: action,
            data: data,
            statusCode: {
                302: function() {
                    nextStep()
                }
            },
            success: () => {
                nextStep()
            },
            error: () => {
                if (debugMode) console.log('there was an error');
            },
            complete: () => {
                UI.$updateCartAjax.removeAttr('disabled','disabled')
                enableInputFields()
            }
        }
        if (debugMode) console.log(request);
        disableInputFields()
        $.ajax(request)
    } else {
        // this step must not have an action, so continue
        nextStep()
    }
}

function handleCountryChange()
{
    // get the value of the selected country.
    const cid = UI.$addressCountry.val();
    const $states = UI.$addressCountry.closest('.addressBox').find('select.address-stateId');
    $states.find('option').remove();

    if (states.hasOwnProperty(cid))
    {
        // We have states for this country, show the states drop down.
        $states.closest('[data-state-wrapper]').show();
        $states.attr('name', $states.data('modelname')+'[stateValue]');

        // Add all states as options to drop down.
        for (let idx in states[cid])
        {
            let state = states[cid][idx];
            let $option = $('<option/>');
            $option.attr('value', state.id).text(state.name);
            $option.attr('data-autocomplete-dropdown-key', state.abbreviation)
            $states.append($option);
        }

    } else {
        // hide the states dropdown, since this country has none.
        $states.closest('[data-state-wrapper]').hide();
        $states.removeAttr('name');
    }
}

function handleFormSubmit(e) {
    e.preventDefault()
    document.forms['checkoutForm'].submit()
}

function handleAcceptTermsChange() {
    if (UI.$acceptTerms.is(':checked')) {
        UI.$placeOrder.removeAttr('disabled')
        UI.$placeOrder.removeAttr('disabled')
    } else {
        UI.$placeOrder.attr('disabled', 'disabled')
        UI.$placeOrder.attr('disabled', 'disabled')
    }
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function handlePlaceOrder(e) {
    e.preventDefault()
    if (UI.$acceptTerms.is(':checked')) {

        let authData = {}
        authData.clientKey = clientKey;
        authData.apiLoginID = apiLoginId;
        let cardData = {};

        cardData.cardNumber = $('[data-authorize=\'number\']').val()
        cardData.month = pad($('[data-authorize=\'exp-month\']').val(), 2) // Pad with zero
        cardData.year = (parseInt($('[data-authorize=\'exp-year\']').val()) % 100).toString() // Get last 2 digits
        cardData.cardCode = $('[data-authorize=\'cvc\']').val()
        cardData.fullName = $('[data-authorize=\'name\']').val()

        let secureData = {};
        secureData.authData = authData;
        secureData.cardData = cardData;

        Accept.dispatchData(secureData, (response) => {
            if (response.messages.resultCode === "Error") {
                let i = 0;
                errors = ''
                while (i < response.messages.message.length) {
                    console.log(
                        response.messages.message[i].code + ': ' +
                        response.messages.message[i].text
                    );
                    errors += response.messages.message[i].text + ' '
                    i = i + 1;
                }
                renderErrorsList()
            } else {
                // Add the tokens
                let dataValue = response.opaqueData.dataValue
                let dataDescriptor = response.opaqueData.dataDescriptor
                let $form = $(forms.payment);
                $form.append($('<input type="hidden" name="opaqueDataValue"/>').val(dataValue))
                $form.append($('<input type="hidden" name="opaqueDataDescriptor"/>').val(dataDescriptor))

                // Clear form fields to prevent touching PCI data
                $('input[type=text],input[type=number]', $form).val('')

                forms.payment.submit()
            }
        });
    }
}

function handleSameAsShippingChange() {
    if (billing.$sameAsShipping.is(':checked')) {
        billing.$addressInputs.attr('disabled', 'disabled')
        billing.$addressDropdowns.attr('disabled', 'disabled')
        // billing.$addressInputs.hide()
        // billing.$addressDropdowns.hide()
        // billing.$addressLabels.hide()

        forms.billingAddress.elements['billingAddress[firstName]'].value = shippingAddress.firstName
        forms.billingAddress.elements['billingAddress[lastName]'].value = shippingAddress.lastName
        forms.billingAddress.elements['billingAddress[address1]'].value = shippingAddress.address1
        forms.billingAddress.elements['billingAddress[address2]'].value = shippingAddress.address2
        forms.billingAddress.elements['billingAddress[city]'].value = shippingAddress.city
        forms.billingAddress.elements['billingAddress[countryId]'].value = shippingAddress.countryId
        forms.billingAddress.elements['billingAddress[stateValue]'].value = shippingAddress.stateId
        forms.billingAddress.elements['billingAddress[zipCode]'].value = shippingAddress.zipCode
        forms.billingAddress.elements['billingAddress[phone]'].value = shippingAddress.phone
        forms.billingAddress.elements['billingAddress[alternativePhone]'].value = shippingAddress.alternativePhone

    } else {
        billing.$addressInputs.removeAttr('disabled')
        billing.$addressDropdowns.removeAttr('disabled')
        billing.$addressInputs.show()
        billing.$addressDropdowns.show()
        billing.$addressLabels.show()
    }
}


// ----------- INIT -----------
function parse_query_string() {
    const p_i = (window.location.href).indexOf('#')
    const reactive = (window.location.href).substring(p_i)
    if (reactive.length <= 0) return {};
    const ind = reactive.indexOf('?')
    let query = reactive.substring(ind+1);
    let vars = query.split('&');
    let query_string = {};
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof query_string[key] === 'undefined') {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof query_string[key] === 'string') {
            let arr = [query_string[key], decodeURIComponent(value)];
            query_string[key] = arr;
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value));
        }
    }
    return query_string;
}

function getCurrentStep() {
    let query = parse_query_string()
    if ('step' in query && _step !== query['step']) {
        _step = query['step']
        goToStep(_step)
    }
    return _step;
}

function selectCountry() {
    if (window.orderAddress) {
        let $countryOptions = UI.$addressCountry.find(`option[value="${window.orderAddress.countryId}"]`)
        $countryOptions.attr('selected', 'selected')
    }
}

function selectDefaultCountry() {
    const defaultCountryId = 233
    if (window.orderAddress) {
        let $countryOptions = UI.$addressCountry.find(`option[value="${defaultCountryId}"]`)
        $countryOptions.attr('selected', 'selected')
    }
}

function selectState() {
    if (window.orderAddress) {
        let $stateOptions = UI.$addressState.find(`option[value="${window.orderAddress.stateId}"]`)
        $stateOptions.attr('selected', 'selected')
    }
}

function renderErrorsList() {
    if (errors && errors.length > 0) {
        UI.$errorDisplay.html(errors)
        UI.$errorDisplay.css('color','red')
        UI.$errorDisplay.show()
    } else {
        UI.$errorDisplay.hide()
    }
}

function init() {
    $('[data-checkout-step]').hide()

    if (forms.billingAddress) {
        // if failed at particular step, reset to that step
        getCurrentStep()

        if (!_step) {
            nextStep()
        }
    }

    $(setupEventHandlers)


    // run onChange event handlers to ensure correct initial state
    // $(selectCountry)
    $(selectDefaultCountry)
    $(handleCountryChange)
    $(handleAcceptTermsChange)
    $(handleSameAsShippingChange)
    $(selectState)

    // show errors
    $(renderErrorsList)
}

export default init
