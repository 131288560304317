import * as types from './mutation-types'
import debounce from 'lodash-es/debounce'

export const openModal = debounce(({commit}, data) => {
    commit(types.SHOW_MODAL, data)
}, 300)

export const hideModal = debounce(({commit}) => {
    commit(types.HIDE_MODAL)
}, 300)

export const processBeganLoading = ({commit}) => {
    commit(types.LOADING_INC)
}

export const processFinishedLoading = ({commit}) => {
    commit(types.LOADING_DEC)
}