import $ from 'jquery'
require('waypoints/lib/noframework.waypoints')
require('waypoints/lib/shortcuts/inview')

// Scroll to
function scrollTo() {
    $('[data-scroll-to]').click(function(e) {
        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash)
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']')
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 100
                }, 500)
                e.preventDefault()
            }
        }
    })
}


// Keep Scrolling
function keepScroll() {
    // On-scroll and on-view trigger animation
    if( $('footer').length && $('[data-keep-scroll]').length ) {
        new Waypoint.Inview({
            element: $('footer')[0],
            enter: function() {
                // $(this.element).addClass('hide')
                $('[data-keep-scroll]').addClass('hide')
            },
            exited: function() {
                $('[data-keep-scroll]').removeClass('hide')
            }
        })
    }
}

function init() {
    scrollTo()
    keepScroll()
}

export default init
