<template>
    <div class="venveo-slider">
        <div v-if="images.length > 1 && buttons" class="prev-button badge-circle" @click="prev">
            <span class="caret caret--left"></span>
        </div>
        <div class="image-list">
            <div v-for="img in images" class="slider-slide">
                <div class="slide-content" :id="img.id" :style="backgroundImg(img.src)">
                    <!--<img :src="img.src" :alt="img.alt">-->
                  <div v-if="img.bgFilter" :style="{opacity:img.bgFilter}" class="slide-bg-filter"></div>
                </div>
            </div>
        </div>
        <div v-if="images.length > 1 && buttons" class="next-button badge-circle" @click="next">
            <span class="caret caret--right"></span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {animateAll} from '../api'

    export default {
        name: "image-slider",
        data() {
            return {
                selectedImgIndex: 0,
                buttons: false,
                lastChange: Date.now(),
            }
        },
        mounted() {
            this.$root.$on('next-slide', this.next)
            this.$root.$on('prev-slide', this.prev)
            this.$root.$on('goto-slide', this.goto)
            const sync = document.querySelectorAll('[data-sync-slide]')
            Object.keys(sync).forEach(k => {
                sync[k].style.opacity = 0
                sync[k].style.display = 'none'
                sync[k].style.position = 'absolute'
            })
            this.setActiveImage(this.selectedImgIndex)
            animateAll(`[data-sync-slide="${this.selectedImgIndex}"]`, {opacity:1, top:100}, {opacity:0, top:200})
            if (this.autoScroll) {
                setInterval(this.autoNext, this.scrollSpeed)
            }
        },
        computed: {
            ...mapGetters(['images', 'autoScroll', 'scrollSpeed']),
            selectedImgId() {
                if (this.images === null || this.images.length <= 0) {
                    return null
                }

                return this.images[this.selectedImgIndex]['id']
            }
        },
        methods: {
            setActiveImage(idx) {
                if (idx === null) {
                    return null
                }
                if (this.selectedImgIndex !== (parseInt(idx) % this.images.length)) {
                    console.log('changing slide...', this.selectedImgIndex, idx)
                    // update active state for all goto-slide bindings
                    const navLast = document.querySelectorAll(`[data-goto-slide="${this.selectedImgIndex}"]`)
                    Object.keys(navLast).forEach(k => {
                        navLast[k].classList.remove('active')
                    })

                    // update synced slides
                    const syncedBefore = animateAll(`[data-sync-slide="${this.selectedImgIndex}"]`, {opacity:0, left:-100})

                    // change slide
                    const current = document.getElementById(this.selectedImgId)
                    current.style.opacity = '0'
                    this.selectedImgIndex = idx % this.images.length

                    // update synced slides
                    const syncedAfter = animateAll(`[data-sync-slide="${this.selectedImgIndex}"]`, {opacity:1, left:0}, {opacity:0, left:100})
                }
                const next = document.getElementById(this.selectedImgId)
                next.style.opacity = '1';

                // update active state for new slide bindings
                const navNext = document.querySelectorAll(`[data-goto-slide="${this.selectedImgIndex}"]`)
                Object.keys(navNext).forEach(k => {
                    navNext[k].classList.add('active')
                })
                this.lastChange = Date.now()
            },
            goto(slideIndex) {
                this.setActiveImage(slideIndex)
            },
            next() {
                this.setActiveImage(this.selectedImgIndex + 1)
            },
            prev() {
                this.setActiveImage(this.selectedImgIndex - 1 + this.images.length)
            },
            autoNext() {
                if (document.hidden) {
                    // wait to change until document is visible again
                    setTimeout(this.autoNext, 1000)
                } else if (Date.now() - this.lastChange > this.scrollSpeed) {
                    // sufficient time since last change, go to next and then re-queue
                    this.next()
                    setTimeout(this.autoNext, this.scrollSpeed)
                } else {
                    // queue another event for the calculated interval since last change
                    setTimeout(this.autoNext, this.scrollSpeed - (Date.now() - this.lastChange))
                }
            },
            getStyle(imgId){
                return this.isSelected(imgId) ? 'display: block;' : 'display: none;'
            },
            backgroundImg(imgSrc) {
                return 'background-image: url(\''+imgSrc+'\');'
            },
            isSelected(imgId) {
                return this.selectedImgId === imgId
            }
        }
    }
</script>

<style lang="scss">
    .venveo-slider {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 3;
        background: black;
        overflow: hidden;
        .slider-slide {
            position: absolute;
            width: 100%;
            height: 100%;
            .slide-bg-filter {
              z-index: 4;
              background: black;
              position: absolute;
              width: 100%;
              height: 100%;
            }
            .slide-content {
                position: relative;
                width: 100%;
                height: 100%;
                background-position: 50%;
                background-repeat: no-repeat;
                background-attachment: fixed;
                background-size: cover;
                opacity: 0;
                transition: opacity 0.5s linear 0s;
            }
        }
    }
    .prev-button, .next-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
    }
    .prev-button {
        left: -50px;
        background-color: #0e977d;
        .caret {
            border-color: transparent white transparent transparent;
        }
    }
    .next-button {
        right: -50px;
        background-color: #0e977d;
        .caret {
            border-color: transparent transparent transparent white;
        }
    }
    .image-list {
    }
    .image-list img {
    }

    .slider-dots {
        padding: 15px;
        display: block;
        position: absolute;
        [data-goto-slide] {
            cursor: pointer;
            height: 25px;
            width: 25px;
            position: relative;
            display: inline-block;
            @media (max-width: 860px) {
                display: block;
            }
            .dot {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 5px;
                width: 5px;
                background-color: #fff;
                border-radius: 50%;
                display: inline-block;
            }
        }
        [data-goto-slide].active {
            .dot {
                height: 12px;
                width: 12px;
                background-color: transparent;
                border-radius: 50%;
                display: inline-block;
                border: 2px solid #fff;
            }
        }
    }
</style>