import { render, staticRenderFns } from "./product-cover-carousel.vue?vue&type=template&id=03b724c0&scoped=true&"
import script from "./product-cover-carousel.vue?vue&type=script&lang=js&"
export * from "./product-cover-carousel.vue?vue&type=script&lang=js&"
import style0 from "./product-cover-carousel.vue?vue&type=style&index=0&id=03b724c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b724c0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2016490859/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03b724c0')) {
      api.createRecord('03b724c0', component.options)
    } else {
      api.reload('03b724c0', component.options)
    }
    module.hot.accept("./product-cover-carousel.vue?vue&type=template&id=03b724c0&scoped=true&", function () {
      api.rerender('03b724c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/product-finishes-slider/components/product-cover-carousel.vue"
export default component.exports