// ----------------
// UI Modules
// ----------------
// This is for UI modules
// ---------------------------------------------------------------
// End import
import globalJS from './global'

function init() {
    globalJS()
}

export default init
