import config from '../../../config/keys'

export default function init() {
    const mainAddressFields = document.querySelectorAll('[data-autocomplete-source]')
    if (!mainAddressFields.length) {
        return null
    }

    const script = document.createElement('script')
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_PUBLIC_KEY}&libraries=places&callback=googleReady`
    script.defer = true

    window.googleReady = () => {
        initAutocomplete()
    }
    document.head.appendChild(script)

    function initAutocomplete() {
        mainAddressFields.forEach((mainAddressField) => {
            const group = mainAddressField.dataset.autocompleteGroup
            const addressFields = document.querySelectorAll('[data-autocomplete-group="' + group + '"]')
            const autocomplete = new google.maps.places.Autocomplete(
                mainAddressField,
                {
                    types: ['geocode'],
                    componentRestrictions: {country: ['us', 'ca']}
                }
            )
            autocomplete.setFields(['address_component'])
            autocomplete.addListener('place_changed', () => fillInAddress(autocomplete, addressFields))

            google.maps.event.addDomListener(mainAddressField, 'keydown', function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault()
                }
            })
        })
    }

    function fillInAddress(autocomplete, addressFields) {
        // Get the place details from the autocomplete object.
        const place = autocomplete.getPlace()

        let addressData = {
            address1: '',
            city: '',
            state: '',
            zipcode: '',
            country: ''
        }
        for (const component of place.address_components) {
            const addressType = component.types[0]
            if (addressType === 'street_number') {
                addressData.address1 = component.long_name + addressData.address1
            }
            if (addressType === 'route') {
                addressData.address1 = addressData.address1 + ' ' + component.short_name
            }
            if (addressType === 'country') {
                addressData.country = component.short_name
            }
            if (addressType === 'locality') {
                addressData.city = component.long_name
            }
            // e.g. Brooklyn is not a city, but we'll pretend it is
            if (addressType === "sublocality_level_1" && !addressData.city) {
                addressData.city = component.long_name
            }
            if (addressType === 'administrative_area_level_1') {
                addressData.state = component.short_name
            }
            if (addressType === 'postal_code') {
                addressData.zipcode = component.short_name
            }
        }
        addressFields.forEach((addressField) => {
            if (!addressField.dataset || !addressField.dataset.autocomplete) {
                return
            }
            let autoCompleteTargetProperty = addressField.dataset.autocomplete
            if (addressData.hasOwnProperty(autoCompleteTargetProperty)) {
                let setValue = addressData[autoCompleteTargetProperty]
                if (addressField.nodeName.toLowerCase() === 'select') {
                    const option = addressField.querySelector('option[data-autocomplete-dropdown-key="' + setValue + '"]')
                    if (option) {
                        setValue = option.value
                    }
                }
                if (addressField.value != setValue) {
                    $(addressField).trigger('change');
                    addressField.value = setValue
                }
            }
        })
    }
}
