/**
 * This is the entry point for the Vue component.
 * I did this to separate concerns between vanilla JS and Vue code.
 */
import Vue from 'vue'
import App from './app.vue'
import store from './store/index.js'
import ImageSlider from './components/image-slider.vue'

export const ENTRY_POINT = '#finish-slider'

export default function init(settings=null,slot=null,onmount=null) {
    return new Vue({
        el: ENTRY_POINT,
        data() {
            return {
                slot: slot,
            }
        },
        template: '<app />',
        components: { App, ImageSlider },
        store: store(settings),
        mounted() {
            if (onmount instanceof Function) {
                onmount()
            }
        }
    })
}
