// ----------------
// Gallery Page Module
// ----------------
// Calling this module will set up a Loader instance and functions
// for the blog page only.
// ---------------------------------------------------------------
import handlesController from './handles'

function check() {
    return !!document.getElementById('page-handles-listing')
}

function init() {
    if (!check()) return
    handlesController()
}

export default init
