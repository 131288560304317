export function animateAll(selector, properties, start) {
    const elements = document.querySelectorAll(selector)
    Object.keys(elements).forEach(k => {
        const el = elements[k]
        animate(el, properties, start)
    })
}

export function animate(el, properties, start, duration=0.7, interval=5) {
    duration = duration*1000 // convert seconds to milliseconds
    let t = 0
    let id = 0

    // set starting properties, if present
    if (start) {
        if ('opacity' in start) {
            if (start.opacity < 0.5) {
                el.style.display = 'block'
            }
            el.style.opacity = start.opacity
        }
        if ('top' in start) {
            el.style.top = start.top + 'px'
        }
        if ('bottom' in start) {
            el.style.bottom = start.bottom + 'px'
        }
        if ('left' in start) {
            el.style.left = start.left + 'px'
        }
        if ('right' in start) {
            el.style.right = start.right + 'px'
        }
    }

    function frame() {
        if (t <= duration) {
            // calculate tween amount for this animation based on number of frames left
            if ('opacity' in properties) {
                const opacity = parseFloat(el.style.opacity)
                const adjust = opacity + ((properties.opacity - opacity) / ((duration - t) / interval))
                el.style.opacity = adjust
            }
            if ('top' in properties) {
                const top = parseFloat(el.style.top)
                const pixels = top + ((properties.top - top) / ((duration - t) / interval))
                el.style.top = pixels + 'px'
            }
            if ('bottom' in properties) {
                const bottom = parseFloat(el.style.bottom)
                const pixels = top + ((properties.bottom - bottom) / ((duration - t) / interval))
                el.style.bottom = pixels + 'px'
            }
            if ('left' in properties) {
                const left = parseFloat(el.style.left)
                const pixels = left + ((properties.left - left) / ((duration - t) / interval))
                el.style.left = pixels + 'px'
            }
            if ('right' in properties) {
                const right = parseFloat(el.style.right)
                const pixels = right + ((properties.right - right) / ((duration - t) / interval))
                el.style.right = pixels + 'px'
            }
            t += interval
        } else {
            if (el.style.opacity < 0.5) {
                el.style.display = 'none'
            }
            clearInterval(id)
        }
    }
    id = setInterval(frame, interval)
}