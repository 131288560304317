<template>
    <div class="finishes-slider">
        <div v-if="images.length > 1 && buttons" class="prev-button badge-circle" @click="prev">
            <span class="caret caret--left"></span>
        </div>
        <div class="image-list">
            <div v-for="img in images" :class="'slider-slide '+bringToTop(img.id)">
                <div class="slide-content" :id="img.id">
                    <div class="slide-background--blur-container">
                        <div class="slide-background--blur" :style="backgroundImg(img.url)"></div>
                    </div>
                    <div class="slide-background" :style="backgroundImg(img.url)" :alt="img.alt"  @click="onSlideClick(img.href)">
                      <div class="slide-link background-pattern--one background-color--light" :class="bringToTop(img.id)">
                        <a :href="img.sampleUrl" class="button primary has-caret--right">REQUEST A SAMPLE <i class="arrow right"></i></a>
                      </div>
                    </div>
                    <div :class="'slide-caption '+hideIfInactive(img.id)">
                        <p><b>Photo Credit: <a :href="img.href" target="_blank">{{ img.credit }}</a></b></p>
                        <p>{{ img.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="images.length > 1 && buttons" class="next-button badge-circle" @click="next">
            <span class="caret caret--right"></span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {animateAll} from '../api'

    export default {
        name: "image-slider",
        data() {
            return {
                selectedImgIndex: 0,
                buttons: true,
                lastChange: Date.now(),
            }
        },
        mounted() {
            this.$root.$on('next-slide', this.next)
            this.$root.$on('prev-slide', this.prev)
            this.$root.$on('goto-slide', this.goto)

            this.setActiveImage(this.selectedImgIndex)
            if (this.autoScroll) {
                setInterval(this.autoNext, this.scrollSpeed)
            }
        },
        watch: {
            currentSlide(newVal, oldVal) {
                if (oldVal !== newVal && newVal !== this.selectedImgId) {
                    this.gotoId(newVal)
                }
            }
        },
        computed: {
            ...mapGetters(['images', 'autoScroll', 'scrollSpeed']),
            selectedImgId() {
                if (this.images === null || this.images.length <= 0 || !this.images[this.selectedImgIndex]) {
                    return null
                }

                return this.images[this.selectedImgIndex]['id']
            }
        },
        methods: {
            setActiveImage(idx) {
                if (idx === null) {
                    return null
                }
                if (this.selectedImgIndex !== (parseInt(idx) % this.images.length)) {
                    console.log('changing slide...', this.selectedImgIndex, idx)
                    // update active state for all goto-slide bindings
                    const navLast = document.querySelectorAll(`[data-goto-slide="${this.selectedImgIndex}"]`)
                    Object.keys(navLast).forEach(k => {
                        navLast[k].classList.remove('active')
                    })

                    // update synced slides
                    const syncedBefore = animateAll(`[data-sync-slide="${this.selectedImgIndex}"]`, {opacity:0, left:-100})

                    // change slide
                    const current = document.getElementById(this.selectedImgId)
                    current.style.opacity = '0'
                    this.selectedImgIndex = idx % this.images.length
                    this.$emit('change', this.selectedImgId)

                    // update synced slides
                    const syncedAfter = animateAll(`[data-sync-slide="${this.selectedImgIndex}"]`, {opacity:1, left:0}, {opacity:0, left:100})
                }
                const next = document.getElementById(this.selectedImgId)
                next.style.opacity = '1';

                // update active state for new slide bindings
                const navNext = document.querySelectorAll(`[data-goto-slide="${this.selectedImgIndex}"]`)
                Object.keys(navNext).forEach(k => {
                    navNext[k].classList.add('active')
                })
                this.lastChange = Date.now()
            },
            gotoId(slideId) {
                const idx = this.images.findIndex(x => x.id === slideId)
                if (idx >= 0) {
                    this.goto(idx)
                }
            },
            goto(slideIndex) {
                this.setActiveImage(slideIndex)
            },
            next() {
                this.setActiveImage(this.selectedImgIndex + 1)
            },
            prev() {
                this.setActiveImage(this.selectedImgIndex - 1 + this.images.length)
            },
            autoNext() {
                if (document.hidden) {
                    // wait to change until document is visible again
                    setTimeout(this.autoNext, 1000)
                } else if (Date.now() - this.lastChange > this.scrollSpeed) {
                    // sufficient time since last change, go to next and then re-queue
                    this.next()
                    setTimeout(this.autoNext, this.scrollSpeed)
                } else {
                    // queue another event for the calculated interval since last change
                    setTimeout(this.autoNext, this.scrollSpeed - (Date.now() - this.lastChange))
                }
            },
            hideIfInactive(imgId){
                return this.isSelected(imgId) ? '' : 'display-none'
            },
            bringToTop(imgId) {
                return this.isSelected(imgId) ? 'show-on-top' : null
            },
            backgroundImg(imgSrc) {
                return 'background-image: url(\''+imgSrc+'\');'
            },
            isSelected(imgId) {
                return this.selectedImgId === imgId
            },
            onSlideClick(e) {
                return null
            }
        },
        props: {
            currentSlide: {
                type: String
            }
        }
    }
</script>

<style lang="scss">
    .display-none {
        display: none;
    }
    .show-on-top {
        z-index: 99;
    }
    .finishes-slider {
        position: relative;
        width: 100%;
        height: 650px;
        z-index: 3;
        overflow: hidden;
        .slider-slide {
            position: absolute;
            width: 100%;
            height: 100%;
            .slide-content {
                position: relative;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity 0.5s linear 0s;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
                .slide-background {
                    position: relative;
                    width: 100%;
                    height: 600px;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    /*background-color: #e6e6e6;*/
                    background-color: transparent;
                    &--blur {
                        position: absolute;
                        width: 110%;
                        height: 110%;
                        top: -5%;
                        left: -5%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-color: #e6e6e6;
                        filter: blur(12px);
                        -webkit-filter: blur(12px);
                        &-container {
                            position: absolute;
                            width: 100%;
                            height: 600px;
                            top: 0;
                            left: 0;
                            z-index: -1;
                            overflow: hidden;
                        }
                    }
                }
                .slide-caption {
                    margin-top: 10px;
                    p {
                        a {
                            font-family: "Leitura Display Roman";
                            color: #1b6f9f;
                        }
                    }
                }
                .slide-link {
                    padding: 25px 0 0 25px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    a.button {
                        padding: 25px 80px;
                        margin: 0;
                        letter-spacing: 4px;
                    }
                }
            }
        }
    }
    .arrow {
      margin-left: 15px;
      border: 4px solid transparent;
      border-right-color: white;
      border-bottom-color: white;
      display: inline-block;
      padding: 0;
    }

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    .prev-button, .next-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 300px;
        background-color: #eeeeee;
        z-index: 100;
        .caret {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .prev-button {
        left: 50px;
        .caret {
            border-color: transparent grey transparent transparent;
        }
    }
    .next-button {
        right: 50px;
        .caret {
            border-color: transparent transparent transparent grey;
        }
    }

    .image-list {
    }
    .image-list img {
    }

    .slider-dots {
        padding: 15px;
        display: block;
        position: absolute;
        [data-goto-slide] {
            cursor: pointer;
            height: 25px;
            width: 25px;
            position: relative;
            display: inline-block;
            @media (max-width: 860px) {
                display: block;
            }
            .dot {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: 5px;
                width: 5px;
                background-color: #fff;
                border-radius: 50%;
                display: inline-block;
            }
        }
        [data-goto-slide].active {
            .dot {
                height: 12px;
                width: 12px;
                background-color: transparent;
                border-radius: 50%;
                display: inline-block;
                border: 2px solid #fff;
            }
        }
    }
</style>