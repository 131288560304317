// ----------------
// Gallery Page Module
// ----------------
// Calling this module will set up a Loader instance and functions
// for the blog page only.
// ---------------------------------------------------------------
import galleryController from './gallery'

function check() {
    return !!document.getElementById('gallery-grid-container')
}

function init() {
    if (!check()) return
    galleryController()
    // galleryModal()
    // gallery filtering
    // gallery whatever else
}

export default init
