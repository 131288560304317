<template>
    <div>
        <modal :style="displayStyle" v-on:close="hideModal">
            <loading-panel v-if="isLoading" />
            <slot/>
        </modal>
    </div>
</template>

<script>
    import Modal from "./components/modal.vue";
    import {mapActions} from 'vuex'
    import {mapGetters} from 'vuex'
    import LoadingPanel from "./components/loading-panel.vue";

    export default {
        components: {
            LoadingPanel,
            Modal
        },
        name: "app",
        created() {
            this.$root.$on('open-modal', this.openModal)
        },
        beforeDestroy() {
            this.$root.$off('open-modal', this.openModal)
        },
        computed: {
            ...mapGetters(['isLoading','modalVisible']),
            displayStyle() {
                return 'display:' + (this.modalVisible ? 'block':'none')
            }
        },
        methods: {
            ...mapActions(['openModal','hideModal'])
        }
    }
</script>

<style scoped>

</style>