import '../images/256x145.jpg'
import '../images/256x145@2x.jpg'

import  '../fonts/Dia-Regular.eot'
import  '../fonts/Dia-Regular.ttf'
import  '../fonts/Dia-Regular.woff'
import  '../fonts/Dia-Regular.woff2'
import  '../fonts/Dia-Regular.font.svg'

import  '../fonts/Dia-Bold.eot'
import  '../fonts/Dia-Bold.ttf'
import  '../fonts/Dia-Bold.woff'
import  '../fonts/Dia-Bold.woff2'
import  '../fonts/Dia-Bold.font.svg'

import  '../fonts/Lato-Black.eot'
import  '../fonts/Lato-Black.ttf'
import  '../fonts/Lato-Black.woff'
import  '../fonts/Lato-Black.woff2'
import  '../fonts/Lato-Black.font.svg'

import  '../fonts/LeituraNews-Roman1.eot'
import  '../fonts/LeituraNews-Roman1.ttf'
import  '../fonts/LeituraNews-Roman1.woff'
import  '../fonts/LeituraNews-Roman1.woff2'
import  '../fonts/LeituraNews-Roman1.font.svg'

import  '../fonts/LeituraDisplay-Roman.eot'
import  '../fonts/LeituraDisplay-Roman.ttf'
import  '../fonts/LeituraDisplay-Roman.woff'
import  '../fonts/LeituraDisplay-Roman.woff2'
import  '../fonts/LeituraDisplay-Roman.font.svg'

import  '../fonts/TiemposHeadline-Bold.eot'
import  '../fonts/TiemposHeadline-Bold.ttf'
import  '../fonts/TiemposHeadline-Bold.woff'
import  '../fonts/TiemposHeadline-Bold.woff2'
import  '../fonts/TiemposHeadline-Bold.font.svg'

import  '../fonts/TiemposHeadline-Regular.eot'
import  '../fonts/TiemposHeadline-Regular.ttf'
import  '../fonts/TiemposHeadline-Regular.woff'
import  '../fonts/TiemposHeadline-Regular.woff2'
import  '../fonts/TiemposHeadline-Regular.font.svg'

import  '../fonts/WaterletHandOutline-Regular.eot'
import  '../fonts/WaterletHandOutline-Regular.ttf'
import  '../fonts/WaterletHandOutline-Regular.woff'
import  '../fonts/WaterletHandOutline-Regular.woff2'
import  '../fonts/WaterletHandOutline-Regular.font.svg'

import  '../fonts/WaterletHandOutline-Bold.eot'
import  '../fonts/WaterletHandOutline-Bold.ttf'
import  '../fonts/WaterletHandOutline-Bold.woff'
import  '../fonts/WaterletHandOutline-Bold.woff2'
import  '../fonts/WaterletHandOutline-Bold.font.svg'

import  '../fonts/WaterletHandFill-Regular.eot'
import  '../fonts/WaterletHandFill-Regular.ttf'
import  '../fonts/WaterletHandFill-Regular.woff'
import  '../fonts/WaterletHandFill-Regular.woff2'
import  '../fonts/WaterletHandFill-Regular.font.svg'

import  '../fonts/WatermarkHand-Regular.eot'
import  '../fonts/WatermarkHand-Regular.ttf'
import  '../fonts/WatermarkHand-Regular.woff'
import  '../fonts/WatermarkHand-Regular.woff2'
import  '../fonts/WatermarkHand-Regular.font.svg'

import '../images/svg-pattern/pattern-1.svg'
import '../images/svg-pattern/pattern-2.svg'
import '../images/svg-pattern/pattern-3.svg'

import '../images/watermark-logo-footer-white.png'
import '../images/watermark-logo-footer-white@2x.png'

import '../images/watermark-logo-white.png'
import '../images/watermark-logo-white@2x.png'

import '../images/brooklyn-made.png'

import '../images/ada-logo.png'

import '../images/AR-icon.png'
import '../images/watermark-ar-icon.svg'

import '../images/icons-svg/dropdown-caret.svg'