var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.price && _vm.selectedPriceGroupName
    ? _c("div", { staticClass: "product-finish-pricing" }, [
        _c("span", { staticClass: "price-group-name" }, [
          _vm._v(_vm._s(_vm.selectedPriceGroupName))
        ]),
        _vm._v("\n  Category Pricing:\n  "),
        _c("span", { staticClass: "price" }, [
          _vm._v("$" + _vm._s(_vm.numberWithCommas(_vm.price)))
        ])
      ])
    : _c("div", { staticClass: "product-finish-pricing" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }