import $ from 'jquery'
import slick from 'slick-carousel'

function init(){
    // Slider 1
    // ---
    // Page: `Range Detail`
    if( document.querySelector('[data-slick-one]') ) {
        let slick1 = $('[data-slick-one]')
        // If slider has `data-slick-counter` attr
        if( slick1.is('[data-slick-counter]') ) {
            let counterVal = slick1.attr('data-slick-counter')
            // counter ID in DOM
            let counter = '#'+counterVal
            slick1.on('init', function(event, slick, currentSlide){
                // init slide
                $(counter).find('[data-slide-current]').text(slick.currentSlide + 1)
                // init total slide count
                $(counter).find('[data-slide-count]').text(slick.slideCount)
            })
            slick1.on('afterChange', function(event, slick, currentSlide){
                // update slide after change
                $(counter).find('[data-slide-current]').text(slick.currentSlide + 1)
            })
        }
        slick1.slick({
            slidesToShow: 1,
            autoplay: true,
            infinite: true,
            rows: 0,
            autoplaySpeed: 5000,
            speed: 800,
            variableWidth: true,
            prevArrow: '<button type="button" class="slick-arrow slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-arrow slick-next"></button>',
            appendArrows: $('[data-slick-one-arrows]'),
        })
    }

    // Slider Sync 1
    // ---
    if( document.querySelector('[data-slicksync-one]') && document.querySelector('[data-slicksync-one-nav]') ) {
        $('[data-slicksync-one]').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            speed: 1000,
            arrows: false,
            fade: true,
            rows: 0,
            prevArrow: '<button type="button" class="slick-arrow slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-arrow slick-next"></button>',
            asNavFor: '[data-slicksync-one-nav]'
        })
        $('[data-slicksync-one-nav]').slick({
            slidesToShow: 6,
            lazyLoad: 'ondemand',
            focusOnSelect: true,
            rows: 0,
            arrows: true,
            speed: 1000,
            prevArrow: '<button type="button" class="slick-arrow slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-arrow slick-next"></button>',
            asNavFor: '[data-slicksync-one]',
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 375,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        })
    }
    // Slider 2
    // ---
    // Page: `About`
    if( document.querySelector('[data-slick-two]') ) {
        let slick2 = $('[data-slick-two]')
        slick2.slick({
            slidesToShow: 1,
            autoplay: false,
            autoplaySpeed: 8000,
            rows: 0,
            speed: 2900,
            arrows: false,
            fade: true, // Will not work with slide, leave this to `true`
            dots: true,
            appendDots: $('[data-slick-two-dots]'),
            customPaging:function(slider, pageIndex) {
                let $year = $(slider.$slides[pageIndex]).data('slideYear')
                return $('<button></button').append('<span>'+$year+'</span>')
            }
        })
    }

    // Related Products
    $('.related-products').slick({
        // dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });


    $('.additional-products-slider').slick({
        // dots: true,
        prevArrow: '<button class="prev-button badge-circle"><span class="caret caret--left"></span></button>',
        nextArrow: '<button class="next-button badge-circle"><span class="caret caret--right"></span></button>',
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });


}

export default init
