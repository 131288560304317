/**
 * Custom Dropdown Component
 *
 *
 */
import controller from './controller'

function check() {
    const customDropdown = document.querySelectorAll('[data-dropjs]')
    return !!(customDropdown.length > 0)
}

function init() {
    if (!check()) return
    controller()
}

export default init
