import $ from 'jquery'
import Isotope from 'isotope-layout/dist/isotope.pkgd'
import bridget from 'jquery-bridget'
// We have to "bridge" the gap between isotope and jQuery.
bridget('isotope', Isotope, $)

let filters = {
    collection: '*'
}

const UI = {
    $finishesList: $('#finishes-list'),
    $dropdownCollections: $('#filter-collections'),
    $iso: null
}

function handleCollectionChange(e) {
    const val = $(e.currentTarget).val()
    filters.collection = null
    if (val !== '*') {
        filters.collection = val
    }
    updateFilters()

    // This will fix issue if we have element with scroll animation `data-animate` attribute element
    Waypoint.refreshAll()
}

function setupEventListeners() {
    UI.$dropdownCollections.change(handleCollectionChange.bind(this))
}


function updateFilters() {
    let filterString = ''

    if (filters.collection) {
        filterString += '.price-'+filters.collection
    }

    UI.$iso.isotope({
        filter: filterString
    })
}

function init() {
    UI.$iso = UI.$finishesList.isotope({
        layoutMode: 'fitRows',
        itemSelector: '.finishes-item',
        percentPosition: true,
        transitionDuration: '0.2s',
    })

    setupEventListeners()
}

export default init
