import * as types from './mutation-types'
const mutations = {
    [types.LOADING_INC](state) {
        state.loadingProcesses += 1
    },
    [types.LOADING_DEC](state) {
        state.loadingProcesses -= 1
    },
}

export default mutations