const imageZoom = function () {
    if (window.innerWidth < 1000) {
        return; // we only want this feature on desktop browsers
    }
    const zoomable = document.querySelectorAll('img.zoomable')
    Object.keys(zoomable).forEach(function (k) {
        const el = zoomable[k]

        const floatingDiv = document.createElement('div')
        floatingDiv.style.display = 'none'
        floatingDiv.style.position = 'absolute'
        floatingDiv.style.width = el.offsetWidth+'px'
        floatingDiv.style.height = el.offsetHeight+'px'
        floatingDiv.style.left = '100%'
        floatingDiv.style.top = '0'
        floatingDiv.style.zIndex = '9999999999999'
        floatingDiv.style.backgroundColor = 'white'
        floatingDiv.style.border = 'solid 1px black'

        const retina = /\[([^,\]]*),[ ]*retina[ ]*\]/i
        const result = el.getAttribute('data-interchange').match(retina)
        if (result.length > 1) {
            floatingDiv.style.backgroundImage = 'url("'+result[1]+'")'
        } else {
            floatingDiv.style.backgroundImage = 'url("' + el.src + '")'
        }
        el.parentNode.appendChild(floatingDiv)

        // const update = () => {
        //     // floatingDiv.style.backgroundImage = 'url("' + el.src + '")'
        //     floatingDiv.style.width = el.offsetWidth+'px'
        //     floatingDiv.style.height = el.offsetHeight+'px'
        // }
        // el.addEventListener('change', update)
        // el.addEventListener('replace', update)
        el.addEventListener('mousemove',(e) => {
            const zoomer = floatingDiv;
            const offsetX = e.offsetX ? e.offsetX : 0
            const offsetY = e.offsetY ? e.offsetY : 0
            const x = offsetX/e.target.offsetWidth*100
            const y = offsetY/e.target.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%'
        })
        el.addEventListener('mouseover', (e) => {
            floatingDiv.style.display = 'block'
        })
        el.addEventListener('mouseout', (e) => {
            floatingDiv.style.display = 'none'
        })
    })
}

const init = function(){
    imageZoom()
}

export default init