var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "venveo-modal" }, [
    _c("div", { staticClass: "venveo-modal-wrap" }, [
      _c("div", { staticClass: "venveo-modal-view" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c(
            "a",
            { staticClass: "modal-exit", on: { click: _vm.dispatchClose } },
            [_c("span", { staticClass: "circle exit" })]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [_vm._t("default")], 2)
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }