// ----------------
// Pages
// ----------------
// This is Pages modules
// ---------------------------------------------------------------

import galleryPage from './gallery'
import collectionsPage from './collections'
import handlesListingPage from './handles'
import finishesListingPage from './finishes'
import dealerPage from './dealers'
import rangePage from './ranges'
import productPage from './products'
import checkout from './store'

function init() {
    galleryPage()
    collectionsPage()
    handlesListingPage()
    finishesListingPage()
    dealerPage()
    rangePage()
    productPage()
    checkout()
}

export default init
