import Headroom from 'headroom.js'

function init(){
    // check if `header` element exist
    if( document.querySelector('header') ) {
        // grab an element
        let header = document.querySelector('header')
        // construct an instance of Headroom, passing the element
        let headroom  = new Headroom(header,{
            'offset': 50,
            'tolerance': 30,
        })
        // initialise
        headroom.init()
    }
}

export default init
