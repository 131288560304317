/**
 * Loading functions for gallery
 */

import Loader from '../../../lib/venveo-loader'
import * as events from '../../../lib/venveo-loader/events'

import imagesLoaded from 'imagesloaded'
import $ from 'jquery'
import debounce from 'lodash-es/debounce'

let galleryLoader = new Loader()

const loadingEl = $('[data-dealer-loading]')
const noResultsEl = $('<div style="width: 100%; text-align: center;"><span>No Results</span></div>')

const config = {
    url: $('[data-loader-url]').data('loaderUrl'),
    $filters: $('[data-filter]'),
    $scrollTarget: $('#scroll-target'),
    itemTemplates: {
        main: $('#tpl-item-small').html()
    },
    container: $('#gallery-grid-container'),
    $resetButton: $('[data-reset]')
}

const loaderSettings = {
    url: config.url,
    itemTemplates: config.itemTemplates,
    itemTemplateSelector: ((item) => {
        return 'main'
    }),
    container: config.container,
    params: {}
}

function complete() {
    const imgLoad = imagesLoaded(document.getElementById('gallery-grid-container'))
    imgLoad.on('progress', () => {
        config.container.isotope('reloadItems')
        config.container.isotope()
    })
    loadingDeactivate()
    if (!galleryLoader.onLastPage()) {
        _bindScrollEvent()
    }
}

function error(error) {
    throw(error)
}

function loading() {
    _unbindScrollEvent()
    noResultsEl.detach()
    loadingActivate()
}

function lastPage() {
    _unbindScrollEvent()
}

function noItems() {
    _unbindScrollEvent()
    noResultsEl.prependTo(config.container)
    // config.$noResults.show()
    // config.$loader.hide()
}

function loadingActivate() {
    loadingEl.addClass('is-loading')
}

function loadingDeactivate() {
    loadingEl.removeClass('is-loading')
}

/**
 * Functions for FILTERING
 */

// This updates the url param
function updateUrlParams(params) {
    const url = new URL(window.location.href)
    const names = Object.getOwnPropertyNames(params)
    for(let i = 0; i < names.length; i++) {
        if (params[names[i]] === '*') {
            url.searchParams.delete(names[i])
        } else {
            url.searchParams.set(names[i], params[names[i]])
        }
    }
    history.pushState({}, document.title, url)
}

// Event listeners for filter dropdowns
function _bindFilterListeners(loader) {
    config.$filters.on('change', (e) => handleFilterChange(loader, e))
    config.$resetButton.on('click', (e) => handleReset(loader, e))
}

function handleReset(loader, e) {
    const values = config.$filters.val('*')
    handleFilterChange(loader, e)
}

function updateResetButtonVisibility(params) {
    let activeParams = [];
    const names = Object.getOwnPropertyNames(params)
    for(let i = 0; i < names.length; i++) {
        if (params[names[i]] !== null && params[names[i]] !== '*') {
            activeParams.push(params[names[i]]);
        }
    }
    if(activeParams.length) {
        config.$resetButton.css('visibility', 'visible');
    } else {
        config.$resetButton.css('visibility', 'hidden');
    }
}

function handleFilterChange(loader, e) {
    config.$filters.each((index, filter) => {
        const $filter = $(filter)
        if ($filter.val() !== '*') {
            filter.parentElement.classList.replace('secondary', 'primary')
        } else {
            filter.parentElement.classList.replace('primary', 'secondary')
        }
    })
    getFilteredItems(loader, getFilterValues())
}

function getFilterValues() {
    let data = {};
    config.$filters.each((index, filter) => {
        const $filter = $(filter)
        const filterName = $filter.data('filter')
        data[filterName] = $filter.val()
    })
    return data
}



// This gets new filtered items
function getFilteredItems(loader, params) {
    // Settings
    let newSettings = {}
    for(var param in params) {
        newSettings[param] = params[param]
    }
    loader.clearAndUpdateParams(newSettings)
    // Actions
    // This needs to get the actual params object, not the newSettings object!
    updateUrlParams(params)
    updateResetButtonVisibility(params)
    loader.getMoreItems()
}

function isScrolledIntoView($elem) {
    var docViewTop = $(window).scrollTop()
    var docViewBottom = docViewTop + $(window).height()

    var elemTop = $elem.offset().top
    var elemBottom = elemTop + $elem.height()

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop))
}

/**
 * Register the scroll event handler
 * @private
 */
function _bindScrollEvent() {
    $(window).scroll(debounce(() => {
        if (isScrolledIntoView(config.$scrollTarget)) {
            loadingEl.prependTo(config.container)
            galleryLoader.getMoreItems()
        }
    }, 250, {leading: true})
    )
}

function _unbindScrollEvent() {
    $(window).unbind('scroll')
}

/**
 * The INIT function, where all the magic starts.
 */

export default function init() {
    galleryLoader.on(events.EVENT_LOADING, e => loading(e))
    galleryLoader.on(events.EVENT_DONE, e => complete(e))
    galleryLoader.on(events.EVENT_NO_DATA, e => noItems(e))
    galleryLoader.addEventListener(events.EVENT_LAST_PAGE, e => lastPage(e))
    // loader.addEventListener(events.EVENT_ERROR, error)
    loaderSettings.params = getFilterValues()
    galleryLoader.init(loaderSettings)
    galleryLoader.getMoreItems()
    _bindFilterListeners(galleryLoader)
}
