'use strict'

import modal, {ENTRY_POINT} from './modal.js'

const entryPoint = document.querySelector(ENTRY_POINT)
const actions = {
    openModal: document.querySelectorAll('[data-open-modal]'),
}

function refreshActions() {
    actions.openModal = document.querySelectorAll('[data-open-modal]')
}

class Core {
    constructor(modal) {
        let target = document.createTextNode(null)
        this.addEventListener = this.on = target.addEventListener.bind(target)
        this.removeEventListener = this.off = target.removeEventListener.bind(target)
        this.dispatchEvent = target.dispatchEvent.bind(target)

        this.properties = {}
        this.listeners = {}
        this.observer = null

        this.vm = modal

        this.init()
    }

    _bindMutationObserver() {
        // more info: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
        // Select the node that will be observed for mutations
        const targetNode = document.documentElement || document.body

        // Options for the observer (which mutations to observe)
        const config = { attributes: false, childList: true, subtree: true }

        // Callback function to execute when mutations are observed
        const callback = (mutationsList, observer) => {
            // rebind event listeners, since we may have more elements
            //console.log('rebinding event listeners')
            this._unbindEventListeners()
            this._bindEventListeners()
        }

        // Create an observer instance linked to the callback function
        this.observer = new MutationObserver(callback)

        //console.log('starting observer')
        // Start observing the target node for configured mutations
        this.observer.observe(targetNode, config)
    }

    _unbindMutationObserver() {
        //console.log('disconnecting observer')
        this.observer.disconnect()
    }

    _bindEventListeners() {
        refreshActions()

        Object.keys(actions.openModal).forEach(k => {
            const el = actions.openModal[k]
            this.listeners[k] = d => {
                // Only trigger the modal if the clicked element doesn't have a "data-no-modal" attribute
                if (d.target.getAttribute('data-no-modal') === null) {
                    d.preventDefault()
                    window.modal.vm.$emit('open-modal', el.getAttribute('data-open-modal'))
                }
            }
            el.addEventListener('click', this.listeners[k])
        })
        //console.log('listeners', this.listeners)
    }

    _unbindEventListeners() {
        Object.keys(actions.openModal).forEach(k => {
            const el = actions.openModal[k]
            if (k in this.listeners) {
                el.removeEventListener('click', this.listeners[k])
            }
        })
    }




    /**
     * Sets settings upon module instantiation AND allows settings to be changed
     * after instantiation.
     * @type {fBound|any}
     */
    defineSettings = (settings) => {
        Object.assign(this.properties, settings)
        return this.properties
    }

    /**
     * Gets the settings of the loader
     * @returns {{page: number, params: {}, url: null, data: null, pageParam: string, container: null, itemTemplates: {}, itemTemplateSelector: null, itemsPerContainer: null, containerTemplates: {}, containerTemplateSelector: null}|*}
     */
    getSettings = () => {
        return this.properties
    }

    init = (settings) => {
        // Settings
        this.defineSettings(settings)

        //console.log('binding event listeners')
        this._bindEventListeners()
        this._bindMutationObserver()
    }
}

export default function init(settings=null) {
    const domReady = function (callback) {
        document.readyState === 'interactive' || document.readyState === 'complete' ? callback() : document.addEventListener('DOMContentLoaded', callback)
    }

    domReady(() => {
        if (!entryPoint) return

        const html = entryPoint.innerHTML
        //console.log('settings',settings)
        //console.log('modalImgs',window.modalImgs)
        const m = modal(settings,html)
        window.modal = new Core(m, html)
    })
}