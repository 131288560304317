var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "carousel-container grid-x grid-margin-x small-up-2 medium-up-4 large-up-5"
    },
    _vm._l(_vm.images, function(img) {
      return _c("div", {
        staticClass: "cell carousel-img",
        class: { active: _vm.currentSlide === img.id },
        style: _vm.backgroundImg(img.url),
        on: {
          click: function($event) {
            return _vm.onClick(img.id)
          }
        }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }