// ----------------
// UI Modules - Forms
// ----------------
// This is UI modules for Global instances
// ---------------------------------------
import dropdownCustom from './dropdown/index'
import hybridSelect from './hybrid-select'

function init() {
    dropdownCustom()
    hybridSelect()
}

export default init
