<template>
  <div class="product-finish-app">
    <product-finish-tab
      title="Explore Finishes"
      :is-active="finishTabActive || !hasExtraTab"
      :current-price-group="currentPriceGroup"
      @click="onFinishTabClick" />
    <product-finish-tab
      v-if="hasExtraTab"
      :title="isElementsProduct ? 'Explore Covers' : 'Explore Handles'"
      :is-active="!finishTabActive"
      :current-price-group="currentPriceGroup"
      @click="onCoversTabClick" />

    <div v-if="finishTabActive || !hasExtraTab" class="product-finish-app--content background-color--light">
      <product-finish-groups :current-price-group="currentPriceGroup" @change="onPriceGroupChange" />
      <product-finish-pricing :price="price" :selected-price-group-name="priceGroupName" />
      <product-finish-carousel :current-price-group="currentPriceGroup" />
    </div>
    <div v-else-if="!finishTabActive" class="product-finish-app--content background-color--light">
      <product-cover-groups :current-cover-group="currentCoverGroup" @change="onCoverGroupChange" />
      <div class="product-cover-description" v-if="currentCoverGroup">
        {{ coverGroup.description }}
      </div>
      <div class="product-cover-description" v-else-if="defaultHandleDescription">
        {{ defaultHandleDescription }}
      </div>
      <product-cover-carousel :current-cover-group="currentCoverGroup" />
    </div>
  </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import ProductFinishCarousel from "./components/product-finish-carousel.vue";
    import ProductFinishTab from "./components/product-finish-tab.vue";
    import ProductFinishPricing from "./components/product-finish-pricing.vue";
    import ProductFinishGroups from "./components/product-finish-groups";
    import ProductCoverCarousel from "./components/product-cover-carousel";
    import ProductCoverGroups from "./components/product-cover-groups";

    export default {
        components: {
          ProductCoverGroups,
          ProductCoverCarousel,
          ProductFinishGroups,
          ProductFinishPricing,
          ProductFinishTab,
          ProductFinishCarousel,
        },
        data() {
          return {
            currentPriceGroup: null,
            currentCoverGroup: null,
            finishTabActive: true
          }
        },
        name: "app",
        created() {
            // this.$root.$on('open-modal', this.openModal)
        },
        beforeDestroy() {
            // this.$root.$off('open-modal', this.openModal)
        },
        computed: {
          ...mapGetters(['priceGroups', 'coverGroups', 'isElementsProduct', 'isHandleStylesProduct', 'defaultHandleDescription']),
          price() {
            return this.priceGroup ? this.priceGroup.price : null
          },
          priceGroupName() {
              return this.priceGroup ? this.priceGroup.title : null
          },
          priceGroup() {
              return this.currentPriceGroup ? this.priceGroups.find(x => x.id === this.currentPriceGroup) : null
          },
          coverGroup() {
            return this.currentCoverGroup ? this.coverGroups.find(x => x.id === this.currentCoverGroup) : null
          },
          displayStyle() {
              return 'display:' + (this.modalVisible ? 'block':'none')
          },
          hasExtraTab() {
            return this.isElementsProduct || this.isHandleStylesProduct
          }
        },
        methods: {
          onPriceGroupChange(e) {
              this.currentPriceGroup = e
          },
          onCoverGroupChange(e) {
            this.currentCoverGroup = e
          },
          onFinishTabClick(e) {
            this.finishTabActive = true
          },
          onCoversTabClick(e) {
            this.finishTabActive = false
          }
        }
    }
</script>

<style lang="scss" scoped>
.product-finish-app {
  width: 100%;
  margin: 50px 20px;
  position: relative;
  &--content {
    border: 1px solid #c9c9c9;
  }
  .product-cover-description {
    display: block;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    color: #1b6f9f;
    font-family: Dia Regular,sans-serif;
  }
}
</style>