var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "venveo-slider" }, [
    _vm.images.length > 1 && _vm.buttons
      ? _c(
          "div",
          { staticClass: "prev-button badge-circle", on: { click: _vm.prev } },
          [_c("span", { staticClass: "caret caret--left" })]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "image-list" },
      _vm._l(_vm.images, function(img) {
        return _c("div", { staticClass: "slider-slide" }, [
          _c(
            "div",
            {
              staticClass: "slide-content",
              style: _vm.backgroundImg(img.src),
              attrs: { id: img.id }
            },
            [
              img.bgFilter
                ? _c("div", {
                    staticClass: "slide-bg-filter",
                    style: { opacity: img.bgFilter }
                  })
                : _vm._e()
            ]
          )
        ])
      }),
      0
    ),
    _vm._v(" "),
    _vm.images.length > 1 && _vm.buttons
      ? _c(
          "div",
          { staticClass: "next-button badge-circle", on: { click: _vm.next } },
          [_c("span", { staticClass: "caret caret--right" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }