'use strict'

import slider, {ENTRY_POINT} from './slider.js'

const entryPoint = document.querySelector(ENTRY_POINT)

class Core {
    constructor(slider) {
        this.properties = {}

        this.vm = slider

        this.init()
    }


    /**
     * Sets settings upon module instantiation AND allows settings to be changed
     * after instantiation.
     * @type {fBound|any}
     */
    defineSettings = (settings) => {
        Object.assign(this.properties, settings)
        return this.properties
    }

    /**
     * Gets the settings of the loader
     * @returns {{page: number, params: {}, url: null, data: null, pageParam: string, container: null, itemTemplates: {}, itemTemplateSelector: null, itemsPerContainer: null, containerTemplates: {}, containerTemplateSelector: null}|*}
     */
    getSettings = () => {
        return this.properties
    }

    init = (settings) => {
        // Settings
        this.defineSettings(settings)
    }
}

export default function init(settings=null) {
    const domReady = function (callback) {
        document.readyState === 'interactive' || document.readyState === 'complete' ? callback() : document.addEventListener('DOMContentLoaded', callback)
    }

    domReady(() => {
        if (!entryPoint) return

        const html = entryPoint.innerHTML
        const m = slider(settings,html)
        window.slider = new Core(m, html)
    })
}