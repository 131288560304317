<template>
    <div>
        <div v-if="images.length > 1" class="prev-button badge-circle" @click="prev">
            <span class="caret caret--left"></span>
        </div>
        <div class="image-list">
            <div v-if="selectedImage">
                <img :src="selectedImage">
            </div>
            <div v-else v-for="img in images" :style="getStyle(img.id)">
                <img :src="img.src" :alt="img.alt">
            </div>
        </div>
        <div v-if="images.length > 1" class="next-button badge-circle" @click="next">
            <span class="caret caret--right"></span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "image-slider",
        data() {
            return {
                selectedImgIndex: 0
            }
        },
        mounted() {
            if (this.selectedImage) {
                this.selectedImgIndex = this.images.findIndex(i => i.id === this.selectedImage)
                if (this.selectedImgIndex < 0) {
                    this.selectedImgIndex = 0
                }
            }
        },
        computed: {
            ...mapGetters(['images', 'selectedImage']),
            selectedImgId() {
                if (this.images === null || this.images.length <= 0) {
                    return null
                }
                return this.images[this.selectedImgIndex].id
            }
        },
        methods: {
            next() {
                this.selectedImgIndex = (this.selectedImgIndex + 1) % this.images.length
            },
            prev() {
                this.selectedImgIndex = (this.selectedImgIndex - 1 + this.images.length) % this.images.length
            },
            getStyle(imgId){
                return this.isSelected(imgId) ? 'display: block' : 'display: none'
            },
            isSelected(imgId) {
                return this.selectedImgId === imgId
            }
        }
    }
</script>

<style lang="scss" scoped>
    .prev-button, .next-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
    }
    .prev-button {
        left: -50px;
        background-color: #0e977d;
        .caret {
            border-color: transparent white transparent transparent;
        }
    }
    .next-button {
        right: -50px;
        background-color: #0e977d;
        .caret {
            border-color: transparent transparent transparent white;
        }
    }
    .image-list img {
        max-width: 75vw;
        max-height: 75vh;
    }
</style>