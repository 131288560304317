export const isLoading = state => state.loadingProcesses > 0
export const finishes = state => state.finishes
export const priceGroups = state => state.priceGroups
export const covers = state => state.covers
export const coverGroups = state => state.coverGroups
export const isElementsProduct = state => state.isElementsProduct
export const isHandleStylesProduct = state => state.isHandleStylesProduct
export const defaultHandleDescription = state => state.defaultHandleDescription
export const autoScroll = state => state.autoScroll
export const scrollSpeed = state => state.scrollSpeed
export const buttons = state => state.buttons