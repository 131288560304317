'use strict'

import $ from 'jquery'
import slider, {ENTRY_POINT} from './slider.js'

const entryPoint = document.querySelector(ENTRY_POINT)
const actions = {
    nextSlide: document.querySelectorAll('[data-next-slide]'),
    prevSlide: document.querySelectorAll('[data-prev-slide]'),
    goToSlide: document.querySelectorAll('[data-goto-slide]'),
}

class Core {
    constructor(slider) {
        let target = document.createTextNode(null)
        this.addEventListener = this.on = target.addEventListener.bind(target)
        this.removeEventListener = this.off = target.removeEventListener.bind(target)
        this.dispatchEvent = target.dispatchEvent.bind(target)

        this.properties = {}

        this.vm = slider

        this.init()
    }

    static _bindEventListeners() {
        Object.keys(actions.nextSlide).forEach(k => actions.nextSlide[k].addEventListener('click', d => {
            d.preventDefault()
            window.slider.vm.$emit('next-slide')
            console.log('next-slide')
        }))
        Object.keys(actions.prevSlide).forEach(k => actions.prevSlide[k].addEventListener('click', d => {
            d.preventDefault()
            window.slider.vm.$emit('prev-slide')
            console.log('prev-slide')
        }))
        Object.keys(actions.goToSlide).forEach(k => actions.goToSlide[k].addEventListener('click', d => {
            d.preventDefault()
            const slideIndex = actions.goToSlide[k].getAttribute('data-goto-slide')
            window.slider.vm.$emit('goto-slide', slideIndex)
            console.log('goto-slide')
        }))
    }


    /**
     * Sets settings upon module instantiation AND allows settings to be changed
     * after instantiation.
     * @type {fBound|any}
     */
    defineSettings = (settings) => {
        Object.assign(this.properties, settings)
        return this.properties
    }

    /**
     * Gets the settings of the loader
     * @returns {{page: number, params: {}, url: null, data: null, pageParam: string, container: null, itemTemplates: {}, itemTemplateSelector: null, itemsPerContainer: null, containerTemplates: {}, containerTemplateSelector: null}|*}
     */
    getSettings = () => {
        return this.properties
    }

    init = (settings) => {
        // Settings
        this.defineSettings(settings)

        Core._bindEventListeners()
    }
}

export default function init(settings=null) {
    const domReady = function (callback) {
        document.readyState === 'interactive' || document.readyState === 'complete' ? callback() : document.addEventListener('DOMContentLoaded', callback)
    }

    domReady(() => {
        if (!entryPoint) return

        const html = entryPoint.innerHTML
        console.log('settings',settings)
        console.log('sliderImgs',window.sliderImgs)
        const m = slider(settings,html)
        window.slider = new Core(m, html)
    })
}