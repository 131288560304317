import $ from 'jquery'

function selectDropdownHybrid() {
    // Up toggle
    $('[data-select-prev-val]').on('click', function() {
        let $parent = $(this).closest('[data-dropdown-hybrid]')
        let $option = $parent.find('select option:selected').prev()
        if (!$option.length) {
            $option = $parent.find('select option').last()
        }
        let $dropdown = $parent.find('select')
        $option.prop('selected', 'selected')
        $dropdown.trigger('change')
    })

    // Down toggle
    $('[data-select-next-val]').on('click', function() {
        let $parent = $(this).closest('[data-dropdown-hybrid]')
        let $option = $parent.find('select option:selected').next()
        if (!$option.length) {
            $option = $parent.find('select option').first()
        }
        let $dropdown = $parent.find('select')
        $option.prop('selected', 'selected')
        $dropdown.trigger('change')
    })
}

function init(){
    selectDropdownHybrid()
}

export default init
