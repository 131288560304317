// ----------------
// Gallery Page Module
// ----------------
// Calling this module will set up a Loader instance and functions
// for the blog page only.
// ---------------------------------------------------------------
import collectionsController from './collections'
// import galleryModal from './galleryModal'

function check() {
    return !!document.getElementById('collections-page')
}

function init() {
    if (!check()) return
    collectionsController()
}

export default init
