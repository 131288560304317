// Gallery Isotope & Filter Module
// ----------------
// Calling this module will init & filter the gallery image tiles
// ---------------------------------------------------------------
import Isotope from 'isotope-layout/dist/isotope.pkgd'
import jQueryBridget from 'jquery-bridget'
import galleryLoader from './galleryLoader'

jQueryBridget('isotope', Isotope, $)

// This function will prevent breaking the layout of the isotope.

function initIsotope() {
    $('#gallery-grid-container').isotope({
        itemSelector: '.gallery-block--item',
        masonry: {
            columnWidth: '.gallery-block--item'
        }
    })
}

function init() {
    initIsotope()
    galleryLoader()
}

export default init
