<template>
    <div v-if="price && selectedPriceGroupName" class="product-finish-pricing">
      <span class="price-group-name">{{ selectedPriceGroupName }}</span>
      Category Pricing:
      <span class="price">${{ numberWithCommas(price) }}</span>
    </div>
    <div v-else class="product-finish-pricing">
    </div>
</template>

<script>
    export default {
        name: "product-finish-pricing",
        methods: {
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        props: {
            selectedPriceGroupName: {

            },
            price: {

            }
        }
    }
</script>

<style scoped>
  .product-finish-pricing {
    display: block;
    position: absolute;
    color: #1b6f9f;
    font-family: 'Dia Regular', sans-serif;
    font-size: 1.5rem;

    top: 155px;
    left: 50%;
    transform: translateX(-50%);
  }
  .price-group-name {
    font-weight: bold;
  }
  .price {
    color: #aaaaaa;
  }
</style>