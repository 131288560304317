import $ from 'jquery'
import debounce from 'lodash-es/debounce'

import Loader from '../../../lib/venveo-loader'
import * as events from '../../../lib/venveo-loader/events'

const config = {
    url: '/api/products/ranges.json',
    filters: {
        $collectionsFilterDropdown: $('#sort-only'),
        $collectionsOrderDropdown: $('#order-by'),
        $collectionButtons: $('[data-filter-collection]')
    },
    itemTemplates: {
        left: $('#tpl-item-left').html(),
        right: $('#tpl-item-right').html(),
    },
    containerTemplates: {
        main: $('#tpl-container').html(),
    },
    container: $('#loader-item-list'),
}

const loadingEl = $('[data-dealer-loading]')

const loaderSettings = {
    url: config.url,
    itemTemplates: config.itemTemplates,
    itemTemplateSelector: ((item) => {
        return 'item'
    }),
    containerTemplateSelector: ((data) => {
        return 'item'
    }),
    containerTemplates: config.containerTemplates,
    container: config.container,
    itemsPerContainer: {
        main: 2
    },
    params: {
        collection: (() => {
            function getParameterByName(name, url) {
                if (!url) url = window.location.href;
                name = name.replace(/[\[\]]/g, '\\$&');
                let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
            // const urlParams = new URLSearchParams(window.location.search)
            return getParameterByName('collection') || '*'
        })(),
        sort: '*'
    },
    templatePattern: [
        {
            type: 'container',
            selector: 'main',
            children: [
                {
                    type: 'item',
                    selector: 'left'
                },
                {
                    type: 'item',
                    selector: 'left'
                },
            ]
        },
        {
            type: 'container',
            selector: 'main',
            children: [
                {
                    type: 'item',
                    selector: 'right'
                },
                {
                    type: 'item',
                    selector: 'right'
                }
            ]
        }
    ]
}

function setupEventHandlers(loader) {
    const debouncePeriod = 250
    config.filters.$collectionsFilterDropdown.on('change', debounce(handleFilterChange.bind(this, loader), debouncePeriod))
    config.filters.$collectionsOrderDropdown.on('change', debounce(handleSortChange.bind(this, loader), debouncePeriod))
    config.filters.$collectionButtons.on('click', debounce(handleCollectionButtonClick.bind(this, loader), debouncePeriod))
}

function handleFilterChange(loader) {
    const collection = getFilterCollection()
    getFilteredItems(loader, {collection})

}

function handleCollectionButtonClick(loader, e) {
    const collection = $(e.currentTarget).data('filterCollection')
    getFilteredItems(loader, {collection})

    config.filters.$collectionsFilterDropdown.val(collection)
}

function handleSortChange(loader) {
    const sort = getFilterSort()
    getFilteredItems(loader, {sort})
}

function done() {
    Foundation.reflow(config.container)
    loadingDeactivate()
}

function loading() {
    loadingActivate()
}

function loadingActivate() {
    loadingEl.addClass('is-loading')
}

function loadingDeactivate() {
    loadingEl.removeClass('is-loading')
}

function getFilterCollection() { return config.filters.$collectionsFilterDropdown.val() }
function getFilterSort() { return config.filters.$collectionsOrderDropdown.val() }



/**
 * Functions for FILTERING
 */
function updateUrlParams(params) {
    const url = new URL(window.location.href)
    const name = Object.getOwnPropertyNames(params)[0]
    const value = params[name]
    if (value === '*') {
        url.searchParams.delete(name)
    } else {
        url.searchParams.set(name, value)
    }
    history.pushState({}, document.title, url)
}

// This gets new filtered items
function getFilteredItems(loader, params) {
    // Settings
    const paramKey = Object.getOwnPropertyNames(params)[0]
    let newSettings = {}
    // Check if the item is 'All Materials'
    if (params[paramKey] !== '*') {
        newSettings = params
    } else {
        newSettings[paramKey] = null
    }
    loader.clearAndUpdateParams(newSettings)
    // Actions
    // This needs to get the actual params object, not the newSettings object!
    updateUrlParams(params)

    // config.$target.css('display', 'initial')
    // config.$noResults.hide()
    loader.getMoreItems()
}

function init() {
    let loader = new Loader()
    loader.addEventListener(events.EVENT_DONE, done)
    // loader.addEventListener(events.EVENT_ERROR, error)
    // loader.addEventListener(events.EVENT_NO_DATA, noItems)
    // loader.addEventListener(events.EVENT_LAST_PAGE, lastPage)
    loader.addEventListener(events.EVENT_LOADING, loading)

    loader.init(loaderSettings)
    loader.getMoreItems()
    setupEventHandlers(loader)
}

export default init
