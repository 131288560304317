<template>
    <div :class="{
      'product-finish-tab': true,
      'active': this.isActive
    }" @click="onTabClick">
      <h3>{{ this.title }} <span v-if="hasArrow" class="caret caret--right" style="margin: 5px 20px;"></span></h3>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "product-finish-tab",
        props: ['title', 'hasArrow', 'currentPriceGroup', 'isActive'],
        computed: {
            ...mapGetters(['priceGroups', 'autoScroll', 'scrollSpeed']),
        },
        methods: {
            getClass(priceGroupId) {
                if (this.currentPriceGroup === priceGroupId) {
                    return 'active'
                }
                return ''
            },
            onTabClick(e) {
                this.$emit('click', e)
            }
        },
    }
</script>

<style lang="scss" scoped>
  .product-finish-tab {
    cursor: pointer;
    display: inline-block;
    padding: 15px;
    background-color: white;
    border-bottom: 1px solid #c9c9c9;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    margin-bottom: -2px;
    &.active {
      background-color: #f4f5f6;
      border-bottom: 2px solid #f4f5f6;
      border-right: 1px solid #c9c9c9;
      border-top: 1px solid #c9c9c9;
      border-left: 1px solid #c9c9c9;
      margin-bottom: -2px;
    }
  }
</style>