import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

export default function store(settings=null) {
    return new Vuex.Store({
        state: {
            debugMode: settings ? (settings.debugMode === true) : false,
            loadingProcesses: 0,
            finishes: settings ? settings.finishes : [],
            priceGroups: settings ? settings.priceGroups : [],
            covers: settings ? settings.covers : [],
            coverGroups: settings ? settings.coverGroups : [],
            isElementsProduct: settings ? settings.isElementsProduct : false,
            isHandleStylesProduct: settings ? settings.isHandleStylesProduct : false,
            defaultHandleDescription: settings ? settings.defaultHandleDescription : null,
            scrollSpeed: 7000,
            autoScroll: false,
            buttons: true,
        },
        getters,
        mutations,
        actions
    })
}

