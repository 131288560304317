// ----------------
// UI Modules - Global
// ----------------
// This is UI modules for Global instances
// ---------------------------------------
import headroom from './headroom'
import toggleIcon from './toggle-icon'
import scrollAnimate from './scroll-animate'
import video from './video'
import scroll from './scroll'
import forms from './forms'
import slick from './slick'
import easterEggs from './easterEggs'
import imageZoom from './imageZoom'
import mmenu from './mmenu'
import autocomplete from './addressAutocomplete'
import augmentedRealityLinks from "./augmentedRealityLinks";
// import hero from './hero'
// import heroCarousel from './hero-carousel'
// import modal from './modal'

function init() {
    headroom()
    scrollAnimate()
    toggleIcon()
    video()
    scroll()
    forms()
    slick()
    easterEggs()
    imageZoom()
    mmenu()
    autocomplete()
    augmentedRealityLinks()
}

export default init
