import $ from 'jquery'

import Loader from '../../../lib/venveo-loader'
import * as events from '../../../lib/venveo-loader/events'

const config = {
    url: '/api/dealers.json',
    filters: {
        $distanceDropdown: $('#distance')
    },
    itemTemplates: {
        main: $('#tpl-item').html()
    },
    containerTemplates: {
        main: $('#tpl-container').html(),
    },
    container: $('#dealer-results-container'),
}

const loaderSettings = {
    url: config.url,
    itemTemplates: config.itemTemplates,
    itemTemplateSelector: ((item) => {
        return 'main'
    }),
    containerTemplateSelector: ((data) => {
        return 'main'
    }),
    containerTemplates: config.containerTemplates,
    container: config.container,
    params: {
        zip: window.requested_zip_code,
        country: window.requested_country,
        range: window.requested_range,
        lat: window.requested_lat,
        lng: window.requested_lng,
    }
}

let filters = {
    range: '*'
}

const UI = {
    findDealerBtn: document.querySelector('[data-find-dealer]'),
    geoLocBtn: document.querySelector('[data-geolocate]'),
    dealerForm: document.querySelector('[data-dealer-form]'),
    dealerLocator: document.querySelector('[data-dealer-locator]'),
    dealerCountry: document.querySelector('[data-dealer-country]'),
    dealerZipInput: document.querySelector('[data-dealer-zip]'),
    dealerLocatorResults: document.querySelector('[data-dealer-locator-results]'),
    geoLatInput: document.querySelector('[data-geo-lat]'),
    geoLngInput: document.querySelector('[data-geo-lng]'),
    loading: document.querySelector('[data-dealer-loading]'),
    distanceCell: document.querySelector('[data-distance-cell]'),
    resultsHeading: document.querySelector('[data-heading-results]'),
    noResultsHeading: document.querySelector('[data-heading-noresults]'),
    errorDisplay: document.querySelector('[data-dealer-errors]')
}

function handleDistanceChange(loader, e) {
    const val = $(e.currentTarget).val()
    filters.range = null
    if (val !== '*') {
        filters.range = val
    }

    console.log(filters)
    console.log(loader)
    getFilteredItems(loader, filters)
}


// This gets new filtered items
function getFilteredItems(loader, params) {
    // Settings
    let newSettings = {}
    for(var param in params) {
        newSettings[param] = params[param]
    }
    loader.clearAndUpdateParams(newSettings)
    loader.getMoreItems()
}

function loadingActivate() {
    UI.loading.classList.add('is-loading')
}

function loadingDeactivate() {
    UI.loading.classList.remove('is-loading')
}

// Get Current Position
function geolocate() {
    navigator.geolocation.getCurrentPosition(success, error)
    function success(Position) {
        getCoords(Position.coords.latitude, Position.coords.longitude)
    }
    function error() {
        // cannot geo locate
        loadingDeactivate()
    }
}

// Will execute once `getCurrentPosition` asynchronously return the position values
function getCoords(lat, lng) {
    updateGeoCoordInputs(lat, lng)
    submitForm()
}

// Update `geoInputs` values
function updateGeoCoordInputs(lat, lng) {
    UI.geoLatInput.value = lat
    UI.geoLngInput.value = lng
}

// Execute form submit
function submitForm() {
    UI.dealerForm.submit()
}

// Use my Location Button Click event
function geoPreSubmitForm() {
    loadingActivate()
    geolocate()
}

// Find Dealer Button Click event
function findDealerPreSubmit() {
    if (UI.dealerCountry.value === 'US' && !UI.dealerZipInput.value) {
        // console.log('address is empty',UI.dealerZipInput.value)
        UI.errorDisplay.innerHTML = '* Required'
        UI.errorDisplay.style = 'display:block;'
        return
    }

    loadingActivate()
    // Give a little bit time to show loading in safari
    setTimeout(submitForm, 800)
}

function setupEventListenersForResults(loader) {
    config.filters.$distanceDropdown.on('change', (e) => handleDistanceChange(loader, e))
}

function isLoading(state) {
    if (state) {
        loadingActivate()
    }
}

function isDone(state) {
    if (!state) {
        loadingDeactivate()
    }
}

function noItems() {
    // UI.resultsHeading.style.display = 'none'
    // UI.distanceCell.style.display = 'none'
    // UI.noResultsHeading.style.display = 'block'
}

function init() {
    if(UI.dealerLocatorResults) {
        let loader = new Loader()
        loader.addEventListener(events.EVENT_LOADING, isLoading.bind(loader, true))
        loader.addEventListener(events.EVENT_DONE, isDone.bind(loader, false))
        // loader.addEventListener(events.EVENT_ERROR, error)
        loader.addEventListener(events.EVENT_NO_DATA, noItems.bind(loader))
        // loader.addEventListener(events.EVENT_LAST_PAGE, lastPage)
        loader.init(loaderSettings)
        loader.getMoreItems()
        setupEventListenersForResults(loader)
    }
    else if(UI.dealerLocator) {
        UI.geoLocBtn.addEventListener('click', geoPreSubmitForm)
        UI.findDealerBtn.addEventListener('click', findDealerPreSubmit)
    }
}

export default init
