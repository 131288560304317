var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.images.length > 1
      ? _c(
          "div",
          { staticClass: "prev-button badge-circle", on: { click: _vm.prev } },
          [_c("span", { staticClass: "caret caret--left" })]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "image-list" },
      [
        _vm.selectedImage
          ? _c("div", [_c("img", { attrs: { src: _vm.selectedImage } })])
          : _vm._l(_vm.images, function(img) {
              return _c("div", { style: _vm.getStyle(img.id) }, [
                _c("img", { attrs: { src: img.src, alt: img.alt } })
              ])
            })
      ],
      2
    ),
    _vm._v(" "),
    _vm.images.length > 1
      ? _c(
          "div",
          { staticClass: "next-button badge-circle", on: { click: _vm.next } },
          [_c("span", { staticClass: "caret caret--right" })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }