// ----------------
// Gallery Page Module
// ----------------
// Calling this module will set up a Loader instance and functions
// for the blog page only.
// ---------------------------------------------------------------
import dealersController from './dealers'

function check() {
    return !!document.getElementById('page-dealer-locator')
}

function init() {
    if (!check()) return
    dealersController()
}

export default init
