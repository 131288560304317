import $ from 'jquery'
import Isotope from 'isotope-layout/dist/isotope.pkgd'
import bridget from 'jquery-bridget'
// We have to "bridge" the gap between isotope and jQuery.
bridget('isotope', Isotope, $)

let filters = {
    range: '*'
}

const UI = {
    $handleList: $('#handles-list'),
    $dropdownRanges: $('#filter-ranges'),
    $dropdownSort: $('#sort-handles'),
    $iso: null
}

function handleRangeChange(e) {
    const val = $(e.currentTarget).val()
    filters.range = null
    if (val !== '*') {
        filters.range = val
    }
    updateFilters()
    // This will fix issue if we have element with scroll animation `data-animate` attribute element
    Waypoint.refreshAll()
}

function handleSortChange(e) {
    const val = $(e.currentTarget).val()
    updateSort(val)
    // This will fix issue if we have element with scroll animation `data-animate` attribute element
    Waypoint.refreshAll()
}

function setupEventListeners() {
    UI.$dropdownRanges.change(handleRangeChange.bind(this))
    UI.$dropdownSort.change(handleSortChange.bind(this))
}


function updateFilters() {
    let filterString = ''

    if (filters.range) {
        filterString += '.range-'+filters.range
    }

    UI.$iso.isotope({
        filter: filterString
    })
}

function updateSort(sortByValue) {
    UI.$iso.isotope({
        sortBy: sortByValue
    })
    UI.$iso.isotope('updateSortData').isotope()
}

function init() {
    UI.$iso = UI.$handleList.isotope({
        layoutMode: 'fitRows',
        itemSelector: '.handle-item',
        percentPosition: true,
        transitionDuration: '0.2s',
        getSortData: {
            name: '[data-title]',
            model: '[data-code]',
        },
        sortBy: 'name'
    })

    setupEventListeners()
}

export default init
